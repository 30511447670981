#rec-pad{
    padding: 30px 80px 5px 80px;
    
}

#rep-h4{
    text-align: center;
    font-weight: 500 !important;
    color: black;
}
#tbl-full{
    width: 100%;
}

#reciept-back{
   
    background: url("./invoicebg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 30px;
  padding-top: 30px;
}


#mr-top-30{
    margin-top: 30px;
}

#logo-title{
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    border: none;
   
}

#refno{
    margin-top: 20px;
    text-align: center;   
}
#txt-left{
    text-align: left;
}


#border-rm{
    border: none !important;
}
#border-rm-txt-left{
    border: none;
    text-align: left;
}

#table-data{
    width: 100%;
    border-collapse: collapse;
}


#border-rm-txt-left-padd-h{
    border: none;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}

#border-rm-txt-left-padd{
    border: none;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 50px;
}

#border-rm-txt-left-padd-d{
    border: none;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
}
#border-rm-txt-left-padd-d-w{
    border: none;
    text-align: left;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
}

#border-rm-txt-left-padd-d-b{
    border: none;
    text-align: left;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
}

#tbl-data-gray{
    background-color: rgb(228, 228, 228,0.4);
    border: none;
}

#tbl-data-white{
    background-color: rgb(255, 255, 255,0.4);
    border: none;
}


#rec-add-tbl{
    width: 100%;
}

#rec-add-tbl-h{
    padding:0;
    border: none !important;
    text-align: left;
    /* padding-left: 50px; */
}

#rec-add-tbl-d{
    text-align: left;
    padding: 0;
    border: none  !important;
    /* padding-right: 50px; */
}

#grand-total-tbl{
    width: 100%;
}

#grand-total-tbl-left{
    padding: 0;
    padding-left: 50px;
    border: none  !important;
    text-align: left;
}
#grand-total-tbl-right{
    padding: 0;
    padding-right: 50px;
    border: none  !important; 
    text-align: left;
}

#table-data-left{
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    border: none  !important;
    text-align: left;
}
#table-data-mid{
    padding: 0;
    padding-right: 10px;
    border: none  !important;
    text-align: left;
}

#table-data-right{
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 50px;
    border: none  !important;
    text-align: left;
}

#txt-center{
    text-align: center;
}
#txt-center-padd{
    text-align: center;
    padding-top: 30px;
}



















#logo-h1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

#logo{
    padding-left: 50px;
   text-align: left;
}

#title{
    padding-right: 50px;
    text-align: right;
    margin-top: -25px;
}

#rec-h1{
    text-align: right;
}

#bottom-left{
    padding: 0;
    padding-left: 50px;
    border: none !important;
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;
}
#bottom-right{
    padding: 0;
    padding-right: 50px;
    border: none !important;
    text-align: right;
    padding-top: 30px;
    padding-bottom: 30px;
}


#projectname{
    margin-top: 20px;
    padding-left: 50px;
}


#invoiceaddressdetails{
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
}


#frm-1{
    padding-left: 50px;
}

#frm-2{
    padding-left: 150px;
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto;
}

#frm-3{
    padding-left: 140px;
}

#frm-4{
    padding-left: 150px;
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto;
}

#col-width{
    width: 100px;
}


#tbl-st-rec{
    margin-top: 20px;
  
}


#tbl-h-gird{
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}


#tbl-d-gird{
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
   background-color: rgb(228, 228, 228,0.4);
   padding-top: 16px;
    padding-bottom: 16px;
}


#tbl-blank-white-gird{
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    background-color: rgb(255, 255, 255,0.4);
    padding-top: 25px;
    padding-bottom: 25px;
}

#tbl-blank-gray-gird{
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    background-color: rgb(228, 228, 228,0.4);
    padding-top: 25px;
    padding-bottom: 25px;
}

#tbl-h-1{
    width: 50px;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-h-2{
    width: 250px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-h-3{
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-h-4{
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-h-5{
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-d-1{
    width: 50px;
    padding-left: 50px;
}

#tbl-d-2{
    width: 250px;
}

#tbl-d-3{
    width: 80px;
}

#tbl-d-4{
    width: 80px;
}

#tbl-d-5{
    width: 100px;
}

#hr-2{
    margin: 0 auto;
    height: 3px;
    width: 90%;
    margin-top: -2px;
    background-color: gray;
}

#grand-total-summary{
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
}

#total-part-1{
    padding-left: 50px;
    width: 250px;   
}

#total-part-2{
    padding-left: 150px;
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto;
}


#trnid{
    font-size: 17px;
}

#amountinnumber{
    font-size: 18px;
}
#totalamt{
    font-size: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
}
#hr-1{
    background-color: black;
    height: 2px;
    border: none;
    margin: 0;
    width: 170px;
}
#hr-1-w{
    background-color: black;
    height: 2px;
    border: none;
    margin: 0;
    width: 130px;
}

#total-text{
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
}
#total-amt-text{
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#paymentinfo{
    margin-top: 20px;
}



.blanktd{
    padding-left: 30% !important;
}

.padd{
    padding: 0px;
    border: none !important;
}
.align{
    text-align: left;
}
.blank{
    padding-right: 30% !important;
}

#amthr{
    font-size: 15px;
    height: 2px;
    background-color: black;
    border: none;
  }
  