.map-card-top{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
    height: 100px;
}

.map-card-midd{
    display: flex;
    justify-content: space-between;
    width: 49%;
    height: 100px;
}
.map-card-bottom{
    width: 48%;
    background-color: brown;
    border-radius: 4px;
}
.map-box{
    text-align: center;
    color: white;
}

.map-box p{
    margin: 0;
    margin-top: 20px;
}

.map-box h3{
    margin: 0;
   margin-top: 5px; 
}


.map-box-1{
    background-image: -webkit-linear-gradient(45deg, #07ba7d 0%, #0fd793 100%);
    background-color: #07ba7d;
}

.map-box-2{
    background-image: -webkit-linear-gradient(45deg, #cf0723 0%, #f63b55 100%);
    background-color: #cf0723;
}

.map-box-3{
    background-image: -webkit-linear-gradient(45deg, #ff7800 0%, #f5a811 100%);
    background-color: #ff7800;
}

.map-box-4{
    background-image: -webkit-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%);
    background-color: #4a19c8;
}


.box-hide-1{
    display: none;
}
.box-hide-2{
    display: none;
}

.box-hide-3{
    display: none;
}

.box-hide-4{
    display: none;
}

.box-hide-5{
  display: none;
}

.url-text-1{
    width: 100%;
}

.url-text-1 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}
.url-text-1 input{
    width: 90%;
}


.eye-st{
    display: flex;
    height: 30px;
    width: 50px;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    background-color: #8226f5 !important;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.eye-st:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}

.eye {
   
    width: 15px;
    height: 15px;
    border: solid 1px #000;
    border-radius:  75% 15%;
    position: relative;
    transform: rotate(45deg);
    background-color: white;
  }
  .eye:before {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border: solid 1px #000;
    border-radius: 50%;
    left: 4px;
    top: 4px;
  }

  .updateMappingSearchByStatus{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 5px;
    color: white;
    font-size: 16px;
    width: 150px;
    height : 35px;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  }

  .updateMappingSearchByStatus:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
  }

  .updateMappingSearchByStatus option {
    margin: 40px;
    background: white;
    color: black;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    padding: 5px;
    font-size: 15px;
}

.updateMappingSearchByStatus option:hover{
    color: rgb(6, 4, 134);
}


.mappingtable {
    width: 100%;
    border-collapse: collapse;
  }

.mappingtable th {
      text-align: left;
  }

.mappingtable, th, td{
  
    padding: 0.75rem;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
  }
  .tbllayout, th, td{
      border: 1px solid rgb(236, 236, 236,0.9) !important;
  }
  .gradienthead{
      /* background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important */
      
  }
  .gradienthead tr th:nth-child(1){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(2){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(3){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(4){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(5){
    background-image: linear-gradient( to right, #8226f5, #E625FD );
  }
  .gradienthead tr th:nth-child(6){
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }

  .gradienthead tr th:nth-child(7){
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }
  .gradienthead tr th:nth-child(8){
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }

  #mapp-unique{
    border-left: none !important;
    border-right: none !important;

  }