
html{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.left{
  float: left;
}
.right{
  float: right;
}
.right span{
  font-size: 1.3em;
  font-weight: 200;
  
}
.right .user{
  margin: 10px;
}
.imageheader{
  width: 30px;
  height: 30px;
}

.ismlogo{
  width: 140px;
  height: 75px;
}
.App-header {
  display:none;
  background-color: #004a80;
  height: 80px;
  color: white;
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
.forminput{
  height: 30px;
  border-color: #222;
}

.vertical-menu {
  width: 200px; 
}

.vertical-menu a {
  background-color: #eee; 
  color: black; 
  display: block; 
  padding: 12px; 
  text-decoration: none; 
}

.vertical-menu a:hover {
  background-color: #ccc; 
}

.vertical-menu a.active {
  background-color: #4CAF50; 
  color: white;
}

.column1{
margin:0px;
padding:0px


}
.column {
    
    
    /* padding: 10px; */

}

.heading{
  left: 43%;
  position: absolute;
  text-align: center; 
  text-transform: uppercase;
  color: rgb(255, 255, 255); 
}






.alert-box-container{
  display: flex;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 23;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  
}

.alert-box{
  width: 400px;
  height: 250px;
  background: white;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #99a1d2c9;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5050e6cc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

