.dashboard-col{
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.set-cus-family{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* .dashboard-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
} */

/* .dashboard-content-color-primary {
    color: #000000;
} */

/* .dashboard-page-title {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    font-weight: normal;
} */

/* .dashboard-content-color-secondary {
    color: #888888;
} */

/* .dashboard-page-sub-title{
    font-size: 14px;
    line-height: 24px;
    margin: 0;
} */
/* .dashboard-col-auto{
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
} */

.dashboard-btn{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dashboard-btn-danger:focus{
    box-shadow: 0 0 0 0.2rem rgba(246, 59, 85, 0.5);
    outline: none;
    color: #fff;
    background-color: #f63b55;
    border-color: #f63b55;
}

.dashboard-btn-danger:hover{
    background-color: #ff1837;
}

.dashboard-btn-success {
    color: #fff;
    background-color: #0fd793;
    border-color: #0fd793;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dashboard-btn-success:hover{
    background-color: #2e9772;
}

.dashboard-btn-success:focus{
    box-shadow: 0 0 0 0.2rem #0fd793;
    outline: none;
    color: #fff;
    background-color: #0ca772;
    border-color: #0b9b6a;
}

.dashboard-btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dashboard-btn-dark:hover{
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.dashboard-btn-dark:focus{
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    outline: none;
}


.dashboard-btn-group, .dashboard-btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

/* .dashboard-btn-danger:not(:disabled):not(.disabled):active:focus, .dashboard-btn-danger:not(:disabled):not(.disabled).active:focus, .show > .dashboard-btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 59, 85, 0.5);
}

.dashboard-btn-danger:not(:disabled):not(.disabled):active, .dashboard-btn-danger:not(:disabled):not(.disabled).active, .show > .dashboard-btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f30b2b;
    border-color: #e70b29;
} */

.dashboard-toggle-arrow{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}



