.toggle-form{
    height: 70px;
    overflow: hidden;
    width: 100%;
    padding-bottom: 50px;
    padding-right: 80px;
    padding-left: 80px;
    background-color: white;
    /* style={{height: "70px",overflow: "hidden", width:"100%", paddingBottom: "50px",paddingRight:"80px",paddingLeft:"80px", backgroundColor: "white"}} */
}


.show-full-div{
    height: 100%;
}

.quota-quali{
    background-color: grey;;
}

.url-mr-top{
    margin-top: 20px;
}

.tg-hide{
    display: none;
}

.quota-tbl-hide{
    display: none;
}

.pro-tbl-hide{
    display: none;
}

.url-text{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 2.0;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 82%;
}
.url-text:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
}

.url-btn{
    padding: 10px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    /* height: 30px; */
    position: absolute;
    right: 0px;
}


.url-btn-color{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
}

.url-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}

.url-input{
    width: "100%";
    display: flex;
    position: relative;
    margin-bottom: 20px;
    /* style={{width: "100%",display: "flex", position: "relative", marginBottom: "20px"}} */
}

.content-sp-bt{
    justify-content: space-between;
}

.pro-box{
    height: 80px;
    padding: 10px;
    width: 20%;
}

.box-color-1{
    background-image: -webkit-linear-gradient(45deg, #07ba7d 0%, #0fd793 100%);
}

.box-color-2{
    background-image: -webkit-linear-gradient(45deg, #cf0723 0%, #f63b55 100%);
}
.box-color-3{
    background-image: -webkit-linear-gradient(45deg, #ff7800 0%, #f5a811 100%);
}

.box-radius{
    border-radius: 3px;
}

.pro-btn{
   padding: 8px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    color: white;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    height:35px;
    width: 150px;
    border: none;
    color: white;
    margin-bottom: 15px;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}
.pro-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}
.mar-bot{
    margin-bottom: 10px;
}

.pro-card-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
}
.card-border{
    border-radius: 5px;
}

.pro-layer{
    height: 100%;
    width: 49%;
    display: flex;
}

.box-1-color{
    background-image: -webkit-linear-gradient(45deg, #07ba7d 0%, #0fd793 100%);
    background-color: #07ba7d !important;
}


.box-2-color{
    background-image: -webkit-linear-gradient(45deg, #cf0723 0%, #f63b55 100%)
}

.box-3-color{
    background-image: -webkit-linear-gradient(45deg, #ff7800 0%, #f5a811 100%);
}

.pro-first-layer{
    display: flex;
    width: 48%;
    justify-content: space-between;
}

.pro-bottom-layer{
    display: flex;
    width: 46%;
    padding-top: 30px;
    justify-content: space-between;
    border-radius: 5px;
  }
.pro-card-content{
    width: 50%;
    padding-left: 10%;
}
.pro-card-content p{
    margin: 0px;
    color: white;
}

.pro-card-content h3{
    margin: 0px;
    text-align: left;
}

.pro-card-img{
    width: 100%;
    text-align: right;
    margin-right: 10px;
}
.pro-card-img img{
    height: 25px;
    width: 25px;
    
}


#pro-tbl th{
    /* background-color: #8226f5; */
    color: white;
    font-size: 15px;
}



/* #pro-tbl {
    width: 100%;
    border-collapse: collapse;
  }


  #pro-tbl th {
      text-align: left;
      color: white;
      font-size: 15px;
  }

  #pro-tbl th, td{
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
    padding: 0.75rem;
    border: none;
  } */



.pro-card-img-circle{
    height: 50px;
    width: 50px;
    line-height: 47px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.pro-tgl-btn{
    height: 40px;
    width: 40px;
    line-height: 36px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    color: blue;
    cursor: pointer;
    font-size: 20px;
}

.pro-tgl-btn-shdw{
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}

.rotate-tgl{
    -ms-transform: rotate(-180deg); /* IE 9 */
    -webkit-transform: rotate(-180deg); /* Chrome, Safari, Opera */
    transform: rotate(-180deg);
}
