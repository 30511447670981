.mainheader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #000000;
    background-color: #ffffff;
    /* background-color: cadetblue; */
    width: 100%;
    height: auto;
    /* min-height: 60px; */
    z-index: 8;
}
.containerfluid{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.alignItemsCenter {
    align-items: center !important;
}
.mainheader-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.mainheader-col-left{
    padding-left : 0 !important;
    flex: 0 0 auto; 
    width: auto; 
    max-width: none;
}

.mainheader-col-center{
   text-align : center;
   flex-basis : 0%;
   flex-grow : 1; 
   max-width : 100%;
   margin-top: 10px;
}
.mainheader-col-right{
    padding-right : 0 !important;
    flex: 0 0 auto; 
    width: auto; 
    max-width: none;
}

.mainheader-slide-menu-btn{
    height:100%;
    line-height: 40px;
    width: 60px;
    border-radius: 0;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    border: none;
    color: white;
}

.mainheader-logo {
    font-weight: 300;
    color: #888888;
    line-height: 30px;
    font-size: 1.4em;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    margin-left: 5px;
    margin-right: 1rem;
    min-width: 120px;
}

.mainheader-logo img {
    max-height: 30px;
    border: 0;
    vertical-align: top;
    margin-right: 0.5rem;
}
.mainheader-time-day{
    list-style: none;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding-left: 0;
    /* padding-top: 15px; */
}
.mainheader-time-day li{
    display: inline-block;
    vertical-align: middle;
}
.mainheader-time-day li p {
    vertical-align: top;
    display: inline-block;
    margin: 0;
    font-size: 1em;
    line-height: 1.4em;
    margin-left: 5px;
}

.mainheader-time-day li h2 {
    vertical-align: top;
    display: inline-block;
    margin: 0 10px;
}

.mainheader-text-right{
    text-align: right !important;
}
.mainheader-time-day li:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.font-weight-light {
    font-weight: 300 !important;
}

.mainheader-time-day li p small {
    display: block;
    font-size: 0.8em;
    line-height: 0.9em;
}

.mainheader-d-inline-block {
    display: inline-block !important;
}

.mainheader-dropup, .mainheader-dropright, .mainheader-dropdown, .mainheader-dropleft {
    position: relative;
}

.mainheader-user-image{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin: 0 10px 0 0;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}
