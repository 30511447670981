.card-text-h4{
    color: #000000;
}

.icon-All{
    color: #ba3fd3;
}

.icon-All:hover{
    text-decoration: underline;
}

.icon-Live:hover{
    text-decoration: underline;
}

.icon-Market{
    color: #0b8000 !important;
}
.icon-Market:hover{
    text-decoration: underline;
}

.icon-Pause{
    color: #f5a811 !important;
}
.icon-Pause:hover{
    text-decoration: underline;
}
.icon-Complete{
    color: #0fd793 !important;
}
.icon-Complete:hover{
    text-decoration: underline;
}

.head-2{
    font-size: 2rem;
    color: black;
    font-weight: 500;
}

.sm-color{
    color: black;
}

.left-align{
    text-align: left;
}
.textcenter{
    text-align: center;
}

.black-color{
    color: black;
}

.shadow{
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}

.main-footer{
    position: absolute;
    bottom: 0;
    z-index: 20;
   
}

.color-secondary {
    color: #888888;
}

.flex-show{
    display: flex !important;
}

.margin-right1{
    margin-right: 20px;
}

.user-profile-main{
    padding: 10px;
    cursor: pointer;
    display: flex;
}

.user-img{
    height: 100%;
}

.user-img img{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin: 5px 15px 0px 0px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}
.right-angle-arrow {
	display: inline-block;
    position: relative;
}
.right-angle-arrow:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ;
    margin-left: 5px;
}

.change{
    border-top: 5px solid  blue;
}
#profileshow:hover .right-angle-arrow::after{
    border-top: 5px solid  blue;
}

.right-angle-arrow:hover::after{
   
    /* border-top: 5px solid  blue; */
}

.profile-poup{
    position: absolute;
    /* transform: translate3d(-129px, 60px, 0px); */
    top: 0px;
    right: 0.5%;
    height: 280px;
    width: 280px;
    border-radius: 3px;
    margin-top: 60px;
    background-color: white;
    z-index: 20;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

#userpopup{
    display: none;
}

.flex-show{
    display: flex;
}

#leftdrower img {
    padding-left: 17px;
    padding-top: 20px;
}

.mainheader-logo img{
   padding-left: 15px;
   padding-top: 15px;
}

#logoutpart1{
    text-align: center;
    align-items: center;
    height: 80%;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
#userimg{
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

#userimg1{
    padding-top: 40px;
    /* height: 120px;
    width: 120px; */
    
}


#userimg1:hover img {
    margin-top: -10%;
    margin-left: -10%;
    width: 120%;
    min-height: 120%;
    transition: ease all 0.3s;
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    -ms-transition: ease all 0.3s;
  }

#username{
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
}


#userimg1 img {
    border-radius: 50%;
    width: 100%;
    min-height: 100%;
    vertical-align: top;
    transition: ease all 0.3s;
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    -ms-transition: ease all 0.3s;
}
#logoutpart2{
    height: 20%;
    border-top-color: rgba(0, 0, 0, 0.05);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
#logoutpart2:hover{
    /* background-color:rgba(211, 160, 131, 0.05); */
}

#logoutpart2 img {
    height: 20px;
    width: 20px;
}

.img-hover-zoom {
    height: 300px; /* Modify this according to your need */
    overflow: hidden; /* Removing this will break the effects */
  }

.img-hover-zoom--quick-zoom img {
    transform-origin: 0 0;
    transition: transform .25s, visibility .25s ease-in;
  }
  
  /* The Transformation */
  .img-hover-zoom--quick-zoom:hover img {
    transform: scale(2);
  }

  .img-hover-zoom {
    height: 300px; /* [1.1] Set it as per your need */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  }
  
  /* [2] Transition property for smooth transformation of images */
  .img-hover-zoom img {
    transition: transform .5s ease;
  }
  
  /* [3] Finally, transforming the image when container gets hovered */
  .img-hover-zoom:hover img {
    transform: scale(1.5);
  }

#routercontainer{
    padding-top: 60px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    margin-left: 225px;
}

.router-header{
    width: 100%;
    background-color: #fafafc;
}

.router-header1{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10%;
    padding-right: 10%;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.head-two-st{
    padding: 0;
    font-size: 24px;
    margin: 0;
    font-weight: 400;
}

.paragraph-st{
    padding: 0;
    margin: 0;
    margin-bottom: 0;
}

.para-st-font{
    font-size: 14px;
}
.letter-spc{
    letter-spacing: 1px;
} 


.list-btn{
    padding: 8px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif
}


.list-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important;   
    /* background-color: #4a19c8; */
}
.margin-right{
    margin-right: 10px;
}


.list-style{
   margin-top: 40px;
   margin-bottom: 40px;
}


.table-st{
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.alltablestruct{
    width: 100%;
    background-color: #FFFFFF;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

}

#search-staus{
    display: flex;
    justify-content: space-between;
}

.searchbox{
    outline: none;
    background-color: rgba(240, 242, 246, 0.5);
    border-radius: 15px;
    line-height: 1em;
    border: 0;
    min-height: 30px;
    height: 30px;
    padding-left: 10px;
}

.searchbox:focus{
    color: gray;
}

.searchbtn{
    background-color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 5px;
}

.status-btn{
    padding: 10px;
}

.status-btn-shadow{
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}

.dropdown-shadow{
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.pad-right-2{
    margin-right: 5px;
}

.status-font{
    font-size: 14px;
}



.tbllayout {
    width: 100%;
    border-collapse: collapse;
    
  }

  .tbllayout th {
      text-align: left;
  }

  .tbllayout, th, td{
    border: 1px solid rgb(236, 236, 236,0.9) !important;
    padding: 0.75rem;
  }
/* 
  #tbllayoutnew{
      border: 0px !important;
    width: 100%;
    border-collapse: collapse
    
  }
  #tbllayoutnew th {
    text-align: left;
    }
   #tbllayoutnew, th{
        border: 0px !important;
    border-top: 1px solid rgb(236, 236, 236,0.9) !important;
    border-bottom: 2px solid rgb(236, 236, 236,0.9) !important;
    }
    #tbllayoutnew, td{
        border: 0px !important;
    
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
    } */

.tbl-new-est{
    width: 100%;
    border-collapse: collapse;
}


.tbl-new-est th{
    border-top:  1px solid rgb(236, 236, 236,0.9) !important;
    border-bottom: 2px solid rgb(236, 236, 236,0.9) !important;
    border-left: none !important;
    border-right: none !important;
    text-align: left !important;
}

.tbl-new-est td{
    border-top:  1px solid rgb(236, 236, 236,0.9) !important;
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
    border-left: none !important;
    border-right: none !important;
}


  /* .tbl-padd-top{
      padding-top: 10px;
  }
  
  .tbl-padd-left{
    padding-left: 7px;
  }
  .tbl-padd-bottom{
      padding-bottom: 10px;
  } */
  .tbl-padd-right{
      padding-right: 20px;
  }
  
.expand-tbl{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
}

.tbl-h-font{
    font-size: 1.3em;
    font-weight: 500;
}


.tbl-th{
    padding-top: 10px;
    padding-left: 7px;
    padding-bottom: 10px;
    padding-right: 7px;
    border-bottom: 1px solid #ccc;
    border-right:  1px solid #ccc;
    text-align: left;
    width: 15%;
}
.tbl-td{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.livebtn{
    padding: 10px;
    cursor: pointer;
    background-color: #7979FF;
    border-color: #7979FF;
    border-radius: 3px;
    color: white;
    outline: none;
    border:none;
}

.livebtn:hover{
    background-color: #5353ff;
    border-color: #4646ff;
}
.pause{
    padding: 10px;
    cursor: pointer;
    background-color: #F5A811;
    border-color: #F5A811;
    border-radius: 3px;
    outline: none;
    border:none;
}

.pause:hover{
    background-color: #d79109;
    border-color: #ca8909;
}


.cmpbtn{
    padding: 10px;
    cursor: pointer;
    background-color: #0FD793;
    border-color: #0FD793;
    border-radius: 3px;
    color: white;
    outline: none;
    border:none;
}

.cmpbtn:hover{
    background-color: #0db37b;
    border-color: #0ca772;
}

.bedg-1{
    height: 20px;
    width: 20px;
    padding: 5px;
    text-align: center;
    background-color: rgb(246, 59, 85);
    border-radius: 50%;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    color: white;
}
.bedg-2{
    height: 20px;
    width: 20px;
    padding: 5px;
    text-align: center;
    background-color: #0fd793;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
}

  .dropdown {
    cursor: pointer;
   
}
.active {
   box-shadow: none;
}

#pageinationstyle{
    text-align: center;
    margin-top: 10px;
  }



.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    background-color: #7979ff;
    border-color: #7979ff;
    /* color: #fff;
    background: -webkit-gradient(linear,left top,right bottom,from(#00f2fe),to(#1e63c3));
    background: -webkit-linear-gradient(top left,#00f2fe 0,#1e63c3);
    background: -o-linear-gradient(top left,#00f2fe 0,#1e63c3 100%);
    background: linear-gradient(to bottom right,#00f2fe 0,#1e63c3);
    border-color: #337ab7; */
    cursor: default;
  }

  .pagination>li>a:hover{
    color: blue !important;
    background-color:rgb(245, 245, 245) !important;
    /* border-color: #7979ff; */
  }
  .pagination>.active>a:hover{
    color: #fff !important;
    background-color: #7979ff !important;
    border-color: #7979ff;
}
  
  .pagination>li>a, .pagination>li>span {
     
    position: relative;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }

  .pagination{
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .flter-btn-downarrow{
    display: inline-block;
    position: relative;
  }
 
  .flter-btn-downarrow:after{
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ;
    margin-left: 10px;
    margin-top: -10px;
  }
  
.dropdownitem{
   display: none;
    width: 150px;
    background: #FFFFFF;
    position: absolute;
    z-index: 1;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;    
}

.dropdownitem>ul>li{
    padding: .75em 1em;
    color: black;
    word-wrap:break-word;
    cursor: pointer;
}

.dropdownitem>ul>li:hover{
    background-color: rgba(240, 242, 246, 0.4);
}

.dropdownitem-filter{
    display: none;
     width: 150px;
     background: #FFFFFF;
     box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
     -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
     -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
     position: absolute;
     z-index: 1;
     border-radius: 3px;
     padding-top: 3px;
     padding-bottom: 3px;    
 }
 
 .dropdownitem-filter>ul>li{
     padding: .75em 1em;
     color: black;
     word-wrap:break-word
 }
 
 .dropdownitem-filter>ul>li:hover{
     background-color: rgba(240, 242, 246, 0.4);
 }
  
 .dropdownitemvendor{
    display: none;
     width: 150px;
     background: #FFFFFF;
     position: absolute;
     z-index: 1;
     border-radius: 3px;
     padding-top: 3px;
     padding-bottom: 3px;    
 }
 
 .dropdownitemvendor>ul>li{
     padding: .75em 1em;
     color: black;
     word-wrap:break-word;
     cursor: pointer;
 }
 
 .dropdownitemvendor>ul>li:hover{
     background-color: rgba(240, 242, 246, 0.4);
 }
 
 .dropdownitemvendor-filter{
     display: none;
      width: 150px;
      background: #FFFFFF;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      padding-top: 3px;
      padding-bottom: 3px;    
  }
  
  .dropdownitemvendor-filter>ul>li{
      padding: .75em 1em;
      color: black;
      word-wrap:break-word
  }
  
  .dropdownitemvendor-filter>ul>li:hover{
      background-color: rgba(240, 242, 246, 0.4);
  }

  .dropdownitemclient{
    display: none;
     width: 150px;
     background: #FFFFFF;
     position: absolute;
     z-index: 1;
     border-radius: 3px;
     padding-top: 3px;
     padding-bottom: 3px;    
 }
 
 .dropdownitemclient>ul>li{
     padding: .75em 1em;
     color: black;
     word-wrap:break-word;
     cursor: pointer;
 }
 
 .dropdownitemclient>ul>li:hover{
     background-color: rgba(240, 242, 246, 0.4);
 }
 
 .dropdownitemclient-filter{
     display: none;
      width: 150px;
      background: #FFFFFF;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      padding-top: 3px;
      padding-bottom: 3px;    
  }
  
  .dropdownitemclient-filter>ul>li{
      padding: .75em 1em;
      color: black;
      word-wrap:break-word
  }
  
  .dropdownitemclient-filter>ul>li:hover{
      background-color: rgba(240, 242, 246, 0.4);
  }

.icons{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    background-color: #8226f5 !important;
    text-align: center;
    font-size: 20px;
    border-radius: 4px;
    height: 30px;
    width: 50px;
    margin: 2px;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
    
}

.pencil{
    /* padding: 5px; */
    color: rgb(101, 101, 231);
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari prior 9.0 */
    transform: rotate(90deg); /* Standard syntax */

}

.delete{
    border-radius: 4px;
}
.delete img{
    height: 15px;
    width: 15px;
}

.pencil img{
    height: 15px;
    width: 15px;
    margin-top: 5px;
}

.plus{
    margin-top: -20px;
    position: absolute;
}
.minus{
    margin-top: -20px;
    position: absolute;
}
.plus::before{
    content: "\2b";
    color: gray;
    margin-top: -20px;
}
.minus::before{
    content: "\2212";
    color: gray;
    margin-top: -20px;
}

.mr-top-40{
    margin-top: 40px;
}

.mr-top-20{
    margin-top: 20px;
}

.create-form{
    margin-top: 20px;
    margin-bottom: 20px;
}

.create-form-container{
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.allformtruct{
    width: 100%;
    background-color: #FFFFFF;
    padding-top: 20px;
    padding-right: 100px;
    padding-bottom: 20px;
    padding-left: 100px;
}
.forms-rd-shadow{
    webkit-box-shadow: 0px 0px 4px 2px rgba(235,221,235,0.71);
    -moz-box-shadow: 0px 0px 4px 2px rgba(235,221,235,0.71);
    box-shadow: 0px 0px 4px 2px rgba(235,221,235,0.71);
    border-radius: 5px;
}

.rm-margin{
    text-align: left;
    margin: 0;
    font-size: 1.1em;
    font-weight: 600 !important;
    color: black;
}
.form-title-text{
    margin-top: 10px;
}

.form-title{
    text-align: left;
    margin-top: 30px;
}
.form-title hr{
    margin-top: 2rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* .input-frame input{
    margin-top: 10px;
    margin-bottom: 20px;
    outline: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-frame input:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
} */

.four-input-frame{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.four-mid{
    width: 49%;
    display: flex;
    justify-content: space-between;
}

.mid-input-fr{
    width: 48%;
}

.input-label{
    width: 100%;
}


.three-input-frame{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.three-input-box1{
    width: 33%;
}
.three-input-w{
    width: 88%;
}
.three-input-w3{
    width: 92%;
}

.form-group {
    display: block;
    margin-bottom: 1rem;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
  }
  
  .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid;
    border-color: #dee2e6;
    box-shadow: 0 1px 2px rgba(175, 138, 138, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    background-color: #dee2e6;
    border-radius : 5px;

  }
  
  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 6px;
    width: 4px;
    height: 9px;
    border: solid #FFFFFF;
    border-radius: 3px;
   
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .form-group input:checked + label:before {
    background-color: #7979ff;
    border-color: #7979ff;
  }


  .formgroup-pdding{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.formgroup{
    display: flex;
    justify-content: space-between;
    margin-top: 1.4em;
}

.formgroup input{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formgroup input:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
} 
.formgroup select{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formgroup select:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
} 

.formgroup-col-6{
    float: left;
    width: 48%;
}

.formgroup-col-6 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formgroup-col-6 input{
    width: 96%;
}

.formgroup-col-3{
    float: left;
    width: 23%;
}

.formgroup-col-3 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}
.formgroup-col-3 input{
    width: 92%;
}

.formgroup-col-1{
    width: 100%;
}
.formgroup-col-1 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}
.formgroup-col-1 input{
    width: 98%;
}

.form-controll{
    background-color: #fafafc;
    border-color: rgba(0, 0, 0, 0.2);
    color: #000000;
    display: block;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formgroup-mar-2{
    margin-bottom: 2rem;
}




.formgroup-col-4{
    float: left;
    width: 31%;
}

.formgroup-col-4 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formgroup-col-4 input{
    width: 94%;
}


.formgroup-col-8{
    float: left;
    width: 80%;
}

.formgroup-col-8 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formgroup-col-8 input{
    width: 96%;
}

.formgroup-col-7{
    float: left;
    width: 65.5%;
}

.formgroup-col-7 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formgroup-col-7 input{
    width: 97%;
}

.formgroup-col-2{
    float: right;
    width: 20% 
}

.check-list-st{
    justify-content: center;
    margin-top: 20px;
}

.check-list-per{
    justify-content: space-between;
    margin-top: 20px;
}


.mr-right-lable label{
    margin-right: 4%;
    
}

.mr-right-20{
    margin-right: 20px;
}

.mr-top-10{
    margin-top: 10px;
}


.zoom-me{
    z-index:22;
    position: fixed;
    height: 99%;
    width:98%;
    top: 0;
    left:0;
    right: 0;
    bottom:0;
    padding: 1%;
    display:block;
}
.router-containter-pd{
    padding-top: 0px;
}

.th-b{
    font-weight: bold;
}

a{
    text-decoration: none;
}


@media (min-width: 992px){
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
}


@media (max-width: 768px){
    .col-lg-3 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }
    .col-lg-2 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }
    .flex-show{
        display: block !important;
    }
}

.errordiv{
    display: none;
    background-color: #d8d8d8;
    color: red;
    width: 97%;
    padding-left: 20px;
    top: 60px;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
}

.areaerror{
    display: none;
    background-color: #d8d8d8;
    color: red;
    width: 98%;
    padding-left: 20px;
    top: 100px;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
}

.errordivcheck{
    display: none;
    color: red;
    width: 98%;
    padding-left: 20px;
    top: 25px;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
}

.erroraddmap{
    display: none;
    background-color: #d8d8d8;
    color: red;
    width: 98%;
    padding-left: 20px;
    top: 35px;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
}

.arrow_box {
	position: relative;
	background: #d8d8d8;
	border: 1px solid #d8d8d8;
}

.arrow_box:after, .arrow_box:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #d8d8d8;
    border-width: 12px;
    margin-left: -15%;
}






.rltv-pos{
    position: relative;
}
.cus-width{
    width: 100%;
}
.cus-width-1{
    width: 85%;
}

.cus-width-2{
    width: 96%;
}
.cus-width-3{
    width:93.5%;
}

.cus-width-4{
    width: 91%;
}

.cus-width-5{
    width: 78%;
}

.cus-width-6{
    width: 92%;
}

.cus-width-7{
    width: 94%;
}

.cus-width-8{
    width: 97%;
}

.cus-width-9{
    width: 98%;
    top: 40px;
}

.showerror{
    display: block;
}

.mr-top-30{
    margin-top: 30px;
}

.spiner-data{
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tblspinner {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .tblspinner:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid rgb(42, 73, 211);
    border-color: rgb(94, 15, 146) transparent rgb(13, 104, 156) transparent;
    animation: tblspinner 1.2s linear infinite;
  }
  @keyframes tblspinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
/*  */

.mr-rhs-20{
    margin-right: 20px;
}
.full-screen {
    vertical-align: middle;
    box-sizing: border-box;
    display: inline-block;
    border: .1em solid currentColor;
    width: 1em;
    height: 1em;
    position: relative;
    cursor: pointer;
    color: gray;
  }
  .full-screen:before, .full-screen:after {
    content: '';
    background: #FFFFFF;
    position: absolute;
    
  }
  .full-screen:before {
    width: .333em;
    height: 1em;
    left: .233em;
    top: -.1em;
  }
  .full-screen:after {
    width: 1em;
    height: .333em;
    top: .233em;
    left: -.1em;
  }
.full-screen:hover{
    color: blue;
}
.container-padd{
    padding-left: 10%;
    padding-right: 10%;
}

.container-padd-1{
    padding-left: 2%;
    padding-right: 2%;
}
.container-padd-2{
    padding-left: 15%;
    padding-right: 15%;
}




#participantgraphead{
    display: flex;
    justify-content: space-between;
    /* padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px; */
    /* height: 10%;
    padding-left: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between; */
  }

  .jutify-cont-sp{
      justify-content: space-between;
  }

#participantgraphslider{
    text-align: right;
    /* display: inline-flex;
    float: right; */
    margin-right: 20px;
   }
  #participantgraphslider .switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 24px;
  }
  
  #participantgraphslider .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  #participantgraphslider .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  #participantgraphslider .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 20px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  #participantgraphslider input:checked + .slider {
    background-color: #2196F3;
  }
  
  #participantgraphslider input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  #participantgraphslider input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
  
  /* Rounded sliders */
  #participantgraphslider .slider.round {
    border-radius: 34px;
  }
  
  #participantgraphslider .slider.round:before {
    border-radius: 50%;
  }
  
  .chartjs-render-monitor{
      width: 98% !important;
  }


  .datanotfound{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

.tg-hide-act-log-form{
    display: none;
}

.tg-hide-act-log-list{
    display: none;
}

textarea{
    resize: none;
}

textarea{
    min-height: 80px;
    width: 330px;
    font-size: 15px !important;
}

.alert-box-shdw{
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}

.invitationalert{
    display: none;
    position: fixed;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(31, 21, 21, 0.8);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
}

.def-st{
    height: 350px;
    width: 500px;
    background-color: white;
    border-radius: 5px;
}


.editbtnst{
    height: 40px;
    width: 40px;
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    right: -5px;
    top: 30px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.tbl-th-fix{
    top: 0;
    position: sticky;
}

.tbl-th-blue{
    background-color: blue;
    color: white;
}

.logo-st{
    margin-bottom: 15px;

}
.logo-st img{
    height: 63.8px;
    width: 243.4px;
}

.demo{
    border: 1px solid rgba(19, 5, 5, 0);
    padding: 0;
}   




.ToolTip{
    position: relative;
    cursor : pointer;
  }
  
  .ToolTipText{
      visibility: hidden;
      background-color: #6c7ae0;
      color: #fff;
      text-align: left !important;
      border-radius: 6px;
      padding: 5px 5px 5px 5px;
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 0%;
      margin-left: 0px;
    height: auto;
    /* position:absolute;
        top:-10px; 
        right:9999px;
        width:200px;
        margin-right:-220px;
        padding:10px;
        color:#fff;
        background:#333;
        z-index: 100; */
  }
  
  
  .ToolTip:hover .ToolTipText {
    visibility: visible;
    }



@media screen and (min-width:1024px) and (max-width:1380px){
   .router-header1{
        padding-left: 2%;
        padding-right: 2%;
   }
   .table-st{
        padding-left: 2%;
        padding-right: 2%;
   }
    
}
@media screen and (min-width:1380px){
    
}

@media screen and (max-width:1380px){
   
}





/* table header fixed */
.fixed_headers thead{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
}


