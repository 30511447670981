
  #dragheading ul{
    width: 100%;
    display: flex;
    padding-bottom: 15px;
    color: black;
  }

  #dragdata ul{ 
    cursor: pointer;
    width: 100%;
    display: flex;
    color: black;
    margin-bottom: 1px;
  }
 

  #dragdata ul:hover{ 
    color: white;
    background-color: #6831c8;
  }

  #counter{
    text-align: center;
    width: 30%;
    font-weight: 700;
  }

#counter1{
  width: 30%;
  text-align: center;
  padding : 10px 0px 10px 0px;
}

#screenername{
    font-weight: 700;
    width: 50%;
    text-align: left;
  }
 
#screenername1{
  /* padding-top: 20px; */
  text-align: left;
  width: 50%;
  padding : 10px 0px 10px 0px;

}
#eyeicon1{
   width: 20%;
}
#eyeicon{
    font-weight: 700;
    width: 20%;
    color: black; 
    padding : 0px 0px 10px 0px;
  }