#leftmenubar{
    /* margin-top: 60px;
    left: 0;
    width: 225px;;
    height: 100%;
    position: fixed;
    z-index: 10;
    background-size: cover;
    background-position: -100px 0;
    background-repeat: no-repeat;
   background-image: url('./forestback.jpg'); 
    background: #5d2cb4;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s; */

     height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 225px;
    overflow-y: auto;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    z-index: 5;
    background-image: url('./sidebar-2.png'); 
    background-repeat: repeat;
    background-size: auto;
    background-position: bottom;
    background-color: #5d2cb4;
    /* background-size: cover;
    background-position: -100px 0;
    background-repeat: no-repeat; */
    margin-top: 60px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); 
}

#leftsubcontainer{
    /* position: relative;
    background: rgba(7, 87, 218, 0.75);
    color: #FFFFFF;
    font: 18px Arial, sans-serif;
    width:225px;
    height: 100%; */

    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 225px;
    overflow-y: auto;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    z-index: 5;
    background-image: url('./sidebar-2.png'); 
    background-repeat: repeat;
    background-size: auto;
    background-position: bottom;
    background-color: #5d2cb4;
    /* background-size: cover;
    background-position: -100px 0;
    background-repeat: no-repeat; */
    /* margin-top: 60px; */
   
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);     
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


#leftmenulist{
    list-style: none;
}

 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 200px;
    color: white;
    width: 100%;
  }
  ul li{
     
     
  }
  
  li a {
    display: flex;
    /* padding: 5px 25px 10px; */
    padding: 3px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    /* font-weight: 00; */
  }
  
  li a.active {
    background-color: #6831c8;
    color: white;
  }
  
  li a:hover:not(.active) {
    background-color: #6831c8;
  }

  .menuicons {
      padding-top: 10px;
      padding-bottom: 5px;
  }
  .menuicons span{
    padding-top: 15px;
  }
  
  .pad-top-5{
    padding-top: 5px
  }

  .menuicons img {
      margin-right: 25px;
      color: rgba(255, 255, 255, 0.7);
      padding-left: 15px;
  }
  
  .backblue{
    background-color: #6831c8;
      color: white;
  }

/* 
  @font-face {
    font-family: 'revicons';
    src: url('../../font/lisboasansosf-italic.otf') format("otf"),;

    font-weight: normal;
    font-style: normal;
  } */

  /* @font-face {
    font-family: "Bitstream Vera Serif Bold";
    src: url("https://mdn.mozillademos.org/files/2468/VeraSeBd.ttf");
  } */
/* .menuicons{
  font-family: Lucida Console, Courier, monospace;
}
  */