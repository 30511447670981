

#logmain{
    background-image: url('./logback.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed; 
    background-size: cover;
    background-position: top;
    min-height: 100vh;
   display: flex;
  
}

#logform{
        padding: 20px;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        font: 18px Arial, sans-serif;
        min-height : 80vh;
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
}

#logform h1,h4{
    text-align: center;
    color: white;
    font-weight: 300 !important;
    text-align: center;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#logform h1{
    font-size: 2.6rem;
}

#logform h4{
    margin: 0;
    margin-bottom: 1rem;
    font-size:1.2em
}
.mb{
    margin-bottom: 1rem;
}

.mt{
    margin-top: 0.5rem;
}


#logform .inputtext{
    text-align: center;
    font-size: 18px !important;
    padding: 15px 22px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    width: 90%;
    margin-bottom: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
}

#logform .inputtext:focus{
    color: gray;
}


.inputtext input:focus{
    background-color: chartreuse;
    border: 2px solid blue;
}

#signbtn{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    border-radius: 5px;
    padding: 0.375rem 0.75rem;
    border: none;
    outline: none;
    color: white;
   font-size: 1rem;
   cursor: pointer;
   height: 40px;
}

#signbtn:hover{
    background-image: -webkit-linear-gradient(45deg,#631cbd 0%, #a50fa8 100%);
}

#heading1{
    text-transform: none;

}

#heading1 span{
    font-weight: 400 !important;
}   


#note {
    display: none;
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    background: #fde073;
    text-align: center;
    line-height: 2.5;
    overflow: hidden; 
    -webkit-box-shadow: 0 0 5px black;
    -moz-box-shadow:    0 0 5px black;
    box-shadow:         0 0 5px black;
    -webkit-transform: translateY(-50px);
    -webkit-animation: slideDown 1.0s 0s 1 ease forwards;
    -moz-transform:    translateY(-50px);
    -moz-animation:    slideDown 1.0s 0s 1 ease forwards;
}


@-webkit-keyframes slideDown {
    0%, 100% { -webkit-transform: translateY(-50px); }
    10%, 90% { -webkit-transform: translateY(0px); }
}
@-moz-keyframes slideDown {
    0%, 100% { -moz-transform: translateY(-50px); }
    10%, 90% { -moz-transform: translateY(0px); }
}



/* input[type=text] {
   
    transition: ease-in-out, width .35s ease-in-out;
  }
  
  input[type=text]:focus {
    background-color: chartreuse;
  }

  input[type=password] {
    transition: ease-in-out, width .35s ease-in-out;
  }
  
  input[type=password]:focus {
    background-color: chartreuse;
  } */