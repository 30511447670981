/* 
@media (min-width: 1200px){
    .dashboard-container {
        max-width: 1140px;
    }
}


@media (min-width: 992px){
    .dashboard-container {
        max-width: 960px;
    }
}

@media (min-width: 768px){
    .dashboard-container {
        max-width: 720px;
    }
}

@media (min-width: 576px){
    .dashboard-container {
        max-width: 540px;
    }
}

.dashboard-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} */

.dashboard-dropdown-menu.showYear {
    display: none;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.dashboard-dropdown-menu.showMonth {
    display: none;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.dashboard-dropdown-menu.showUsers {
    display: none;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.dashboard-dropdown-menu[x-placement^="top"], .dashboard-dropdown-menu[x-placement^="right"], .dashboard-dropdown-menu[x-placement^="bottom"], .dashboard-dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dashboard-dropdown-menu {
    padding: 5px 0;
    border-radius: 4px;
    border: 0;
    margin-top: -1px;
    /* position: absolute;
    top: 100%;
    left: 0; */
    z-index: 1000;
    /* display: none; */
    float: left;
    min-width: 10rem;
    /* padding: 0.5rem 0; */
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; */
}

.dashboard-dropdown-menu .dashboard-dropdown-item:first-child {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}
.dashboard-dropdown-menu .dashboard-dropdown-item {
    padding: .75em 1em;
}
.dashboard-dropdown-item {
    display: block;
    /* width: 100%; */
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dashboard-dropdown-menu .dashboard-dropdown-item:first-child {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

.dashboard-dropdown-item:hover{
    background-color:  #e9e9e9;
    cursor: pointer;
}


/* //-----------------------------------------------------------------------------------------------// */

  #dashboard-card{
    display: flex;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.5);
    padding: 15px;
    height: -webkit-fill-available;
  }

  #dashboard-card-icon{
    height: 50px;
    width: 50px;
    line-height: 47px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    margin-right: 1rem !important;
  }
  .dashboard-card-icon-success{
    background-color: rgba(15, 215, 147, 0.15) !important;
    color: #0fd793;
  }

  .dashboard-card-icon-danger{
    background-color: rgba(246, 59, 85, 0.15) !important;
    color: #f63b55;
  }

  .dashboard-card-icon-warning{
    background-color: rgba(245, 168, 17, 0.15) !important;
    color: #f5a811;
  }

  .dashboard-card-icon-primary{
    background-color: rgba(121, 121, 255, 0.15) !important;
    color: #7979ff;
  }

  #dashboard-card-text p{
    line-height: 1.4em;
    margin-bottom: 0;
    color: #888888;
    display: inline-block;
    width: 100%;
    margin-top: auto;
  }

  #dashboard-card-text h4{
    font-size: 1.4rem;
    font-weight: 500 !important;
    color: #000000;
    line-height: 1.2;
    margin-top: 0;
    text-align: left;
  }

.dashshadow{
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}
   
.parent-layer{
  width: 100%;
  height: 95px;
  display: flex;
  justify-content: space-between;
}
.middle-layer{
  height: 100%;
  width: 48.6%;
  display: flex;
}

.bottom-layer{
 width: 47%;
 height: 100%;
}

.layer-r-mr{
  margin-right: 30px;
}
.white-back{
  background-color: #FFFFFF;
}

.dashboard-graph{
  width: 100% !important;
}

.dashboard-select select {
  color: #FFFFFF; 
 }

 .dashboard-select option {
  color: black;  
}




@media only screen  and (max-width: 1000px) {
  .middle-layer{
    width: 100%;
    margin-top: 20px;
  }
  .parent-layer{
    display: block;
  }

}


@media only screen and (max-width: 768px) {
  .bottom-layer{
    width: 100%;
    margin-top: 20px;
  }

  .middle-layer{
  display: block;
    margin-bottom: 140px;
  }
  .body{
    background-color: #000000;
  }
} 

