body {
  margin: 0;
  padding: 0;
}


html{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.left{
  float: left;
}
.right{
  float: right;
}
.right span{
  font-size: 1.3em;
  font-weight: 200;
  
}
.right .user{
  margin: 10px;
}
.imageheader{
  width: 30px;
  height: 30px;
}

.ismlogo{
  width: 140px;
  height: 75px;
}
.App-header {
  display:none;
  background-color: #004a80;
  height: 80px;
  color: white;
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
.forminput{
  height: 30px;
  border-color: #222;
}

.vertical-menu {
  width: 200px; 
}

.vertical-menu a {
  background-color: #eee; 
  color: black; 
  display: block; 
  padding: 12px; 
  text-decoration: none; 
}

.vertical-menu a:hover {
  background-color: #ccc; 
}

.vertical-menu a.active {
  background-color: #4CAF50; 
  color: white;
}

.column1{
margin:0px;
padding:0px


}
.column {
    
    
    /* padding: 10px; */

}

.heading{
  left: 43%;
  position: absolute;
  text-align: center; 
  text-transform: uppercase;
  color: rgb(255, 255, 255); 
}






.alert-box-container{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 23;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-align-items: center;
          align-items: center;
  
}

.alert-box{
  width: 400px;
  height: 250px;
  background: white;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #99a1d2c9;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5050e6cc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




#logmain{
    background-image: url(/static/media/logback.10e96338.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed; 
    background-size: cover;
    background-position: top;
    min-height: 100vh;
   display: -webkit-flex;
   display: flex;
  
}

#logform{
        padding: 20px;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        font: 18px Arial, sans-serif;
        min-height : 80vh;
        width: 100%;
        display: -webkit-flex;
        display: flex;
        text-align: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
}

#logform h1,h4{
    text-align: center;
    color: white;
    font-weight: 300 !important;
    text-align: center;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#logform h1{
    font-size: 2.6rem;
}

#logform h4{
    margin: 0;
    margin-bottom: 1rem;
    font-size:1.2em
}
.mb{
    margin-bottom: 1rem;
}

.mt{
    margin-top: 0.5rem;
}


#logform .inputtext{
    text-align: center;
    font-size: 18px !important;
    padding: 15px 22px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    width: 90%;
    margin-bottom: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
}

#logform .inputtext:focus{
    color: gray;
}


.inputtext input:focus{
    background-color: chartreuse;
    border: 2px solid blue;
}

#signbtn{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    border-radius: 5px;
    padding: 0.375rem 0.75rem;
    border: none;
    outline: none;
    color: white;
   font-size: 1rem;
   cursor: pointer;
   height: 40px;
}

#signbtn:hover{
    background-image: -webkit-linear-gradient(45deg,#631cbd 0%, #a50fa8 100%);
}

#heading1{
    text-transform: none;

}

#heading1 span{
    font-weight: 400 !important;
}   


#note {
    display: none;
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    background: #fde073;
    text-align: center;
    line-height: 2.5;
    overflow: hidden;
    box-shadow:         0 0 5px black;
    -webkit-transform: translateY(-50px);
    -webkit-animation: slideDown 1.0s 0s 1 ease forwards;
    -moz-transform:    translateY(-50px);
    -moz-animation:    slideDown 1.0s 0s 1 ease forwards;
}


@-webkit-keyframes slideDown {
    0%, 100% { -webkit-transform: translateY(-50px); }
    10%, 90% { -webkit-transform: translateY(0px); }
}



/* input[type=text] {
   
    transition: ease-in-out, width .35s ease-in-out;
  }
  
  input[type=text]:focus {
    background-color: chartreuse;
  }

  input[type=password] {
    transition: ease-in-out, width .35s ease-in-out;
  }
  
  input[type=password]:focus {
    background-color: chartreuse;
  } */
.mainheader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #000000;
    background-color: #ffffff;
    /* background-color: cadetblue; */
    width: 100%;
    height: auto;
    /* min-height: 60px; */
    z-index: 8;
}
.containerfluid{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.alignItemsCenter {
    -webkit-align-items: center !important;
            align-items: center !important;
}
.mainheader-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.mainheader-col-left{
    padding-left : 0 !important;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto; 
    width: auto; 
    max-width: none;
}

.mainheader-col-center{
   text-align : center;
   -webkit-flex-basis : 0%;
           flex-basis : 0%;
   -webkit-flex-grow : 1;
           flex-grow : 1; 
   max-width : 100%;
   margin-top: 10px;
}
.mainheader-col-right{
    padding-right : 0 !important;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto; 
    width: auto; 
    max-width: none;
}

.mainheader-slide-menu-btn{
    height:100%;
    line-height: 40px;
    width: 60px;
    border-radius: 0;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    border: none;
    color: white;
}

.mainheader-logo {
    font-weight: 300;
    color: #888888;
    line-height: 30px;
    font-size: 1.4em;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    margin-left: 5px;
    margin-right: 1rem;
    min-width: 120px;
}

.mainheader-logo img {
    max-height: 30px;
    border: 0;
    vertical-align: top;
    margin-right: 0.5rem;
}
.mainheader-time-day{
    list-style: none;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding-left: 0;
    /* padding-top: 15px; */
}
.mainheader-time-day li{
    display: inline-block;
    vertical-align: middle;
}
.mainheader-time-day li p {
    vertical-align: top;
    display: inline-block;
    margin: 0;
    font-size: 1em;
    line-height: 1.4em;
    margin-left: 5px;
}

.mainheader-time-day li h2 {
    vertical-align: top;
    display: inline-block;
    margin: 0 10px;
}

.mainheader-text-right{
    text-align: right !important;
}
.mainheader-time-day li:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.font-weight-light {
    font-weight: 300 !important;
}

.mainheader-time-day li p small {
    display: block;
    font-size: 0.8em;
    line-height: 0.9em;
}

.mainheader-d-inline-block {
    display: inline-block !important;
}

.mainheader-dropup, .mainheader-dropright, .mainheader-dropdown, .mainheader-dropleft {
    position: relative;
}

.mainheader-user-image{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin: 0 10px 0 0;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}

.card-text-h4{
    color: #000000;
}

.icon-All{
    color: #ba3fd3;
}

.icon-All:hover{
    text-decoration: underline;
}

.icon-Live:hover{
    text-decoration: underline;
}

.icon-Market{
    color: #0b8000 !important;
}
.icon-Market:hover{
    text-decoration: underline;
}

.icon-Pause{
    color: #f5a811 !important;
}
.icon-Pause:hover{
    text-decoration: underline;
}
.icon-Complete{
    color: #0fd793 !important;
}
.icon-Complete:hover{
    text-decoration: underline;
}

.head-2{
    font-size: 2rem;
    color: black;
    font-weight: 500;
}

.sm-color{
    color: black;
}

.left-align{
    text-align: left;
}
.textcenter{
    text-align: center;
}

.black-color{
    color: black;
}

.shadow{
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}

.main-footer{
    position: absolute;
    bottom: 0;
    z-index: 20;
   
}

.color-secondary {
    color: #888888;
}

.flex-show{
    display: -webkit-flex !important;
    display: flex !important;
}

.margin-right1{
    margin-right: 20px;
}

.user-profile-main{
    padding: 10px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
}

.user-img{
    height: 100%;
}

.user-img img{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin: 5px 15px 0px 0px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}
.right-angle-arrow {
	display: inline-block;
    position: relative;
}
.right-angle-arrow:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ;
    margin-left: 5px;
}

.change{
    border-top: 5px solid  blue;
}
#profileshow:hover .right-angle-arrow::after{
    border-top: 5px solid  blue;
}

.right-angle-arrow:hover::after{
   
    /* border-top: 5px solid  blue; */
}

.profile-poup{
    position: absolute;
    /* transform: translate3d(-129px, 60px, 0px); */
    top: 0px;
    right: 0.5%;
    height: 280px;
    width: 280px;
    border-radius: 3px;
    margin-top: 60px;
    background-color: white;
    z-index: 20;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

#userpopup{
    display: none;
}

.flex-show{
    display: -webkit-flex;
    display: flex;
}

#leftdrower img {
    padding-left: 17px;
    padding-top: 20px;
}

.mainheader-logo img{
   padding-left: 15px;
   padding-top: 15px;
}

#logoutpart1{
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    height: 80%;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
#userimg{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    text-align: center;
}

#userimg1{
    padding-top: 40px;
    /* height: 120px;
    width: 120px; */
    
}


#userimg1:hover img {
    margin-top: -10%;
    margin-left: -10%;
    width: 120%;
    min-height: 120%;
    transition: ease all 0.3s;
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    -ms-transition: ease all 0.3s;
  }

#username{
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
}


#userimg1 img {
    border-radius: 50%;
    width: 100%;
    min-height: 100%;
    vertical-align: top;
    transition: ease all 0.3s;
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    -ms-transition: ease all 0.3s;
}
#logoutpart2{
    height: 20%;
    border-top-color: rgba(0, 0, 0, 0.05);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    cursor: pointer;
}
#logoutpart2:hover{
    /* background-color:rgba(211, 160, 131, 0.05); */
}

#logoutpart2 img {
    height: 20px;
    width: 20px;
}

.img-hover-zoom {
    height: 300px; /* Modify this according to your need */
    overflow: hidden; /* Removing this will break the effects */
  }

.img-hover-zoom--quick-zoom img {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transition: visibility .25s ease-in, -webkit-transform .25s;
    transition: visibility .25s ease-in, -webkit-transform .25s;
    transition: transform .25s, visibility .25s ease-in;
    transition: transform .25s, visibility .25s ease-in, -webkit-transform .25s;
  }
  
  /* The Transformation */
  .img-hover-zoom--quick-zoom:hover img {
    -webkit-transform: scale(2);
            transform: scale(2);
  }

  .img-hover-zoom {
    height: 300px; /* [1.1] Set it as per your need */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  }
  
  /* [2] Transition property for smooth transformation of images */
  .img-hover-zoom img {
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
  }
  
  /* [3] Finally, transforming the image when container gets hovered */
  .img-hover-zoom:hover img {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

#routercontainer{
    padding-top: 60px;
    -webkit-transition: .2s;
    transition: .2s;
    margin-left: 225px;
}

.router-header{
    width: 100%;
    background-color: #fafafc;
}

.router-header1{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10%;
    padding-right: 10%;
    -webkit-transition: .2s;
    transition: .2s;
}

.head-two-st{
    padding: 0;
    font-size: 24px;
    margin: 0;
    font-weight: 400;
}

.paragraph-st{
    padding: 0;
    margin: 0;
    margin-bottom: 0;
}

.para-st-font{
    font-size: 14px;
}
.letter-spc{
    letter-spacing: 1px;
} 


.list-btn{
    padding: 8px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif
}


.list-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important;   
    /* background-color: #4a19c8; */
}
.margin-right{
    margin-right: 10px;
}


.list-style{
   margin-top: 40px;
   margin-bottom: 40px;
}


.table-st{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    -webkit-transition: .2s;
    transition: .2s;
}

.alltablestruct{
    width: 100%;
    background-color: #FFFFFF;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

}

#search-staus{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.searchbox{
    outline: none;
    background-color: rgba(240, 242, 246, 0.5);
    border-radius: 15px;
    line-height: 1em;
    border: 0;
    min-height: 30px;
    height: 30px;
    padding-left: 10px;
}

.searchbox:focus{
    color: gray;
}

.searchbtn{
    background-color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 5px;
}

.status-btn{
    padding: 10px;
}

.status-btn-shadow{
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}

.dropdown-shadow{
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.pad-right-2{
    margin-right: 5px;
}

.status-font{
    font-size: 14px;
}



.tbllayout {
    width: 100%;
    border-collapse: collapse;
    
  }

  .tbllayout th {
      text-align: left;
  }

  .tbllayout, th, td{
    border: 1px solid rgb(236, 236, 236,0.9) !important;
    padding: 0.75rem;
  }
/* 
  #tbllayoutnew{
      border: 0px !important;
    width: 100%;
    border-collapse: collapse
    
  }
  #tbllayoutnew th {
    text-align: left;
    }
   #tbllayoutnew, th{
        border: 0px !important;
    border-top: 1px solid rgb(236, 236, 236,0.9) !important;
    border-bottom: 2px solid rgb(236, 236, 236,0.9) !important;
    }
    #tbllayoutnew, td{
        border: 0px !important;
    
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
    } */

.tbl-new-est{
    width: 100%;
    border-collapse: collapse;
}


.tbl-new-est th{
    border-top:  1px solid rgb(236, 236, 236,0.9) !important;
    border-bottom: 2px solid rgb(236, 236, 236,0.9) !important;
    border-left: none !important;
    border-right: none !important;
    text-align: left !important;
}

.tbl-new-est td{
    border-top:  1px solid rgb(236, 236, 236,0.9) !important;
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
    border-left: none !important;
    border-right: none !important;
}


  /* .tbl-padd-top{
      padding-top: 10px;
  }
  
  .tbl-padd-left{
    padding-left: 7px;
  }
  .tbl-padd-bottom{
      padding-bottom: 10px;
  } */
  .tbl-padd-right{
      padding-right: 20px;
  }
  
.expand-tbl{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
}

.tbl-h-font{
    font-size: 1.3em;
    font-weight: 500;
}


.tbl-th{
    padding-top: 10px;
    padding-left: 7px;
    padding-bottom: 10px;
    padding-right: 7px;
    border-bottom: 1px solid #ccc;
    border-right:  1px solid #ccc;
    text-align: left;
    width: 15%;
}
.tbl-td{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.livebtn{
    padding: 10px;
    cursor: pointer;
    background-color: #7979FF;
    border-color: #7979FF;
    border-radius: 3px;
    color: white;
    outline: none;
    border:none;
}

.livebtn:hover{
    background-color: #5353ff;
    border-color: #4646ff;
}
.pause{
    padding: 10px;
    cursor: pointer;
    background-color: #F5A811;
    border-color: #F5A811;
    border-radius: 3px;
    outline: none;
    border:none;
}

.pause:hover{
    background-color: #d79109;
    border-color: #ca8909;
}


.cmpbtn{
    padding: 10px;
    cursor: pointer;
    background-color: #0FD793;
    border-color: #0FD793;
    border-radius: 3px;
    color: white;
    outline: none;
    border:none;
}

.cmpbtn:hover{
    background-color: #0db37b;
    border-color: #0ca772;
}

.bedg-1{
    height: 20px;
    width: 20px;
    padding: 5px;
    text-align: center;
    background-color: rgb(246, 59, 85);
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: white;
}
.bedg-2{
    height: 20px;
    width: 20px;
    padding: 5px;
    text-align: center;
    background-color: #0fd793;
    border-radius: 50%;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: white;
}

  .dropdown {
    cursor: pointer;
   
}
.active {
   box-shadow: none;
}

#pageinationstyle{
    text-align: center;
    margin-top: 10px;
  }



.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    background-color: #7979ff;
    border-color: #7979ff;
    /* color: #fff;
    background: -webkit-gradient(linear,left top,right bottom,from(#00f2fe),to(#1e63c3));
    background: -webkit-linear-gradient(top left,#00f2fe 0,#1e63c3);
    background: -o-linear-gradient(top left,#00f2fe 0,#1e63c3 100%);
    background: linear-gradient(to bottom right,#00f2fe 0,#1e63c3);
    border-color: #337ab7; */
    cursor: default;
  }

  .pagination>li>a:hover{
    color: blue !important;
    background-color:rgb(245, 245, 245) !important;
    /* border-color: #7979ff; */
  }
  .pagination>.active>a:hover{
    color: #fff !important;
    background-color: #7979ff !important;
    border-color: #7979ff;
}
  
  .pagination>li>a, .pagination>li>span {
     
    position: relative;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }

  .pagination{
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .flter-btn-downarrow{
    display: inline-block;
    position: relative;
  }
 
  .flter-btn-downarrow:after{
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ;
    margin-left: 10px;
    margin-top: -10px;
  }
  
.dropdownitem{
   display: none;
    width: 150px;
    background: #FFFFFF;
    position: absolute;
    z-index: 1;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;    
}

.dropdownitem>ul>li{
    padding: .75em 1em;
    color: black;
    word-wrap:break-word;
    cursor: pointer;
}

.dropdownitem>ul>li:hover{
    background-color: rgba(240, 242, 246, 0.4);
}

.dropdownitem-filter{
    display: none;
     width: 150px;
     background: #FFFFFF;
     box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
     -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
     -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
     position: absolute;
     z-index: 1;
     border-radius: 3px;
     padding-top: 3px;
     padding-bottom: 3px;    
 }
 
 .dropdownitem-filter>ul>li{
     padding: .75em 1em;
     color: black;
     word-wrap:break-word
 }
 
 .dropdownitem-filter>ul>li:hover{
     background-color: rgba(240, 242, 246, 0.4);
 }
  
 .dropdownitemvendor{
    display: none;
     width: 150px;
     background: #FFFFFF;
     position: absolute;
     z-index: 1;
     border-radius: 3px;
     padding-top: 3px;
     padding-bottom: 3px;    
 }
 
 .dropdownitemvendor>ul>li{
     padding: .75em 1em;
     color: black;
     word-wrap:break-word;
     cursor: pointer;
 }
 
 .dropdownitemvendor>ul>li:hover{
     background-color: rgba(240, 242, 246, 0.4);
 }
 
 .dropdownitemvendor-filter{
     display: none;
      width: 150px;
      background: #FFFFFF;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      padding-top: 3px;
      padding-bottom: 3px;    
  }
  
  .dropdownitemvendor-filter>ul>li{
      padding: .75em 1em;
      color: black;
      word-wrap:break-word
  }
  
  .dropdownitemvendor-filter>ul>li:hover{
      background-color: rgba(240, 242, 246, 0.4);
  }

  .dropdownitemclient{
    display: none;
     width: 150px;
     background: #FFFFFF;
     position: absolute;
     z-index: 1;
     border-radius: 3px;
     padding-top: 3px;
     padding-bottom: 3px;    
 }
 
 .dropdownitemclient>ul>li{
     padding: .75em 1em;
     color: black;
     word-wrap:break-word;
     cursor: pointer;
 }
 
 .dropdownitemclient>ul>li:hover{
     background-color: rgba(240, 242, 246, 0.4);
 }
 
 .dropdownitemclient-filter{
     display: none;
      width: 150px;
      background: #FFFFFF;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      padding-top: 3px;
      padding-bottom: 3px;    
  }
  
  .dropdownitemclient-filter>ul>li{
      padding: .75em 1em;
      color: black;
      word-wrap:break-word
  }
  
  .dropdownitemclient-filter>ul>li:hover{
      background-color: rgba(240, 242, 246, 0.4);
  }

.icons{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    background-color: #8226f5 !important;
    text-align: center;
    font-size: 20px;
    border-radius: 4px;
    height: 30px;
    width: 50px;
    margin: 2px;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.icons:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
    
}

.pencil{
    /* padding: 5px; */
    color: rgb(101, 101, 231); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari prior 9.0 */
    transform: rotate(90deg); /* Standard syntax */

}

.delete{
    border-radius: 4px;
}
.delete img{
    height: 15px;
    width: 15px;
}

.pencil img{
    height: 15px;
    width: 15px;
    margin-top: 5px;
}

.plus{
    margin-top: -20px;
    position: absolute;
}
.minus{
    margin-top: -20px;
    position: absolute;
}
.plus::before{
    content: "+";
    color: gray;
    margin-top: -20px;
}
.minus::before{
    content: "\2212";
    color: gray;
    margin-top: -20px;
}

.mr-top-40{
    margin-top: 40px;
}

.mr-top-20{
    margin-top: 20px;
}

.create-form{
    margin-top: 20px;
    margin-bottom: 20px;
}

.create-form-container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    -webkit-transition: .2s;
    transition: .2s;
}

.allformtruct{
    width: 100%;
    background-color: #FFFFFF;
    padding-top: 20px;
    padding-right: 100px;
    padding-bottom: 20px;
    padding-left: 100px;
}
.forms-rd-shadow{
    webkit-box-shadow: 0px 0px 4px 2px rgba(235,221,235,0.71);
    box-shadow: 0px 0px 4px 2px rgba(235,221,235,0.71);
    border-radius: 5px;
}

.rm-margin{
    text-align: left;
    margin: 0;
    font-size: 1.1em;
    font-weight: 600 !important;
    color: black;
}
.form-title-text{
    margin-top: 10px;
}

.form-title{
    text-align: left;
    margin-top: 30px;
}
.form-title hr{
    margin-top: 2rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* .input-frame input{
    margin-top: 10px;
    margin-bottom: 20px;
    outline: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-frame input:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
} */

.four-input-frame{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}

.four-mid{
    width: 49%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.mid-input-fr{
    width: 48%;
}

.input-label{
    width: 100%;
}


.three-input-frame{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}

.three-input-box1{
    width: 33%;
}
.three-input-w{
    width: 88%;
}
.three-input-w3{
    width: 92%;
}

.form-group {
    display: block;
    margin-bottom: 1rem;
  }

  .form-group input {
    padding: 0;
    height: auto;
    height: initial;
    width: auto;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
  }
  
  .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid;
    border-color: #dee2e6;
    box-shadow: 0 1px 2px rgba(175, 138, 138, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    background-color: #dee2e6;
    border-radius : 5px;

  }
  
  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 6px;
    width: 4px;
    height: 9px;
    border: solid #FFFFFF;
    border-radius: 3px;
   
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .form-group input:checked + label:before {
    background-color: #7979ff;
    border-color: #7979ff;
  }


  .formgroup-pdding{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.formgroup{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 1.4em;
}

.formgroup input{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formgroup input:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
} 
.formgroup select{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formgroup select:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
} 

.formgroup-col-6{
    float: left;
    width: 48%;
}

.formgroup-col-6 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formgroup-col-6 input{
    width: 96%;
}

.formgroup-col-3{
    float: left;
    width: 23%;
}

.formgroup-col-3 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}
.formgroup-col-3 input{
    width: 92%;
}

.formgroup-col-1{
    width: 100%;
}
.formgroup-col-1 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}
.formgroup-col-1 input{
    width: 98%;
}

.form-controll{
    background-color: #fafafc;
    border-color: rgba(0, 0, 0, 0.2);
    color: #000000;
    display: block;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formgroup-mar-2{
    margin-bottom: 2rem;
}




.formgroup-col-4{
    float: left;
    width: 31%;
}

.formgroup-col-4 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formgroup-col-4 input{
    width: 94%;
}


.formgroup-col-8{
    float: left;
    width: 80%;
}

.formgroup-col-8 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formgroup-col-8 input{
    width: 96%;
}

.formgroup-col-7{
    float: left;
    width: 65.5%;
}

.formgroup-col-7 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.formgroup-col-7 input{
    width: 97%;
}

.formgroup-col-2{
    float: right;
    width: 20% 
}

.check-list-st{
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
}

.check-list-per{
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 20px;
}


.mr-right-lable label{
    margin-right: 4%;
    
}

.mr-right-20{
    margin-right: 20px;
}

.mr-top-10{
    margin-top: 10px;
}


.zoom-me{
    z-index:22;
    position: fixed;
    height: 99%;
    width:98%;
    top: 0;
    left:0;
    right: 0;
    bottom:0;
    padding: 1%;
    display:block;
}
.router-containter-pd{
    padding-top: 0px;
}

.th-b{
    font-weight: bold;
}

a{
    text-decoration: none;
}


@media (min-width: 992px){
    .col-lg-3 {
        -webkit-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-2 {
        -webkit-flex: 0 0 16.66667%;
                flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
}


@media (max-width: 768px){
    .col-lg-3 {
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }
    .col-lg-2 {
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }
    .flex-show{
        display: block !important;
    }
}

.errordiv{
    display: none;
    background-color: #d8d8d8;
    color: red;
    width: 97%;
    padding-left: 20px;
    top: 60px;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
}

.areaerror{
    display: none;
    background-color: #d8d8d8;
    color: red;
    width: 98%;
    padding-left: 20px;
    top: 100px;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
}

.errordivcheck{
    display: none;
    color: red;
    width: 98%;
    padding-left: 20px;
    top: 25px;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
}

.erroraddmap{
    display: none;
    background-color: #d8d8d8;
    color: red;
    width: 98%;
    padding-left: 20px;
    top: 35px;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
}

.arrow_box {
	position: relative;
	background: #d8d8d8;
	border: 1px solid #d8d8d8;
}

.arrow_box:after, .arrow_box:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #d8d8d8;
    border-width: 12px;
    margin-left: -15%;
}






.rltv-pos{
    position: relative;
}
.cus-width{
    width: 100%;
}
.cus-width-1{
    width: 85%;
}

.cus-width-2{
    width: 96%;
}
.cus-width-3{
    width:93.5%;
}

.cus-width-4{
    width: 91%;
}

.cus-width-5{
    width: 78%;
}

.cus-width-6{
    width: 92%;
}

.cus-width-7{
    width: 94%;
}

.cus-width-8{
    width: 97%;
}

.cus-width-9{
    width: 98%;
    top: 40px;
}

.showerror{
    display: block;
}

.mr-top-30{
    margin-top: 30px;
}

.spiner-data{
    height: 300px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.tblspinner {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .tblspinner:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid rgb(42, 73, 211);
    border-color: rgb(94, 15, 146) transparent rgb(13, 104, 156) transparent;
    -webkit-animation: tblspinner 1.2s linear infinite;
            animation: tblspinner 1.2s linear infinite;
  }
  @-webkit-keyframes tblspinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes tblspinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
/*  */

.mr-rhs-20{
    margin-right: 20px;
}
.full-screen {
    vertical-align: middle;
    box-sizing: border-box;
    display: inline-block;
    border: .1em solid currentColor;
    width: 1em;
    height: 1em;
    position: relative;
    cursor: pointer;
    color: gray;
  }
  .full-screen:before, .full-screen:after {
    content: '';
    background: #FFFFFF;
    position: absolute;
    
  }
  .full-screen:before {
    width: .333em;
    height: 1em;
    left: .233em;
    top: -.1em;
  }
  .full-screen:after {
    width: 1em;
    height: .333em;
    top: .233em;
    left: -.1em;
  }
.full-screen:hover{
    color: blue;
}
.container-padd{
    padding-left: 10%;
    padding-right: 10%;
}

.container-padd-1{
    padding-left: 2%;
    padding-right: 2%;
}
.container-padd-2{
    padding-left: 15%;
    padding-right: 15%;
}




#participantgraphead{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    /* padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px; */
    /* height: 10%;
    padding-left: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between; */
  }

  .jutify-cont-sp{
      -webkit-justify-content: space-between;
              justify-content: space-between;
  }

#participantgraphslider{
    text-align: right;
    /* display: inline-flex;
    float: right; */
    margin-right: 20px;
   }
  #participantgraphslider .switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 24px;
  }
  
  #participantgraphslider .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  #participantgraphslider .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  #participantgraphslider .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 20px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  #participantgraphslider input:checked + .slider {
    background-color: #2196F3;
  }
  
  #participantgraphslider input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  #participantgraphslider input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
  
  /* Rounded sliders */
  #participantgraphslider .slider.round {
    border-radius: 34px;
  }
  
  #participantgraphslider .slider.round:before {
    border-radius: 50%;
  }
  
  .chartjs-render-monitor{
      width: 98% !important;
  }


  .datanotfound{
    height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 20px;
  }

.tg-hide-act-log-form{
    display: none;
}

.tg-hide-act-log-list{
    display: none;
}

textarea{
    resize: none;
}

textarea{
    min-height: 80px;
    width: 330px;
    font-size: 15px !important;
}

.alert-box-shdw{
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}

.invitationalert{
    display: none;
    position: fixed;
    /* display: flex; */
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(31, 21, 21, 0.8);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
}

.def-st{
    height: 350px;
    width: 500px;
    background-color: white;
    border-radius: 5px;
}


.editbtnst{
    height: 40px;
    width: 40px;
    background-color: blue;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    position: absolute;
    right: -5px;
    top: 30px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.tbl-th-fix{
    top: 0;
    position: -webkit-sticky;
    position: sticky;
}

.tbl-th-blue{
    background-color: blue;
    color: white;
}

.logo-st{
    margin-bottom: 15px;

}
.logo-st img{
    height: 63.8px;
    width: 243.4px;
}

.demo{
    border: 1px solid rgba(19, 5, 5, 0);
    padding: 0;
}   




.ToolTip{
    position: relative;
    cursor : pointer;
  }
  
  .ToolTipText{
      visibility: hidden;
      background-color: #6c7ae0;
      color: #fff;
      text-align: left !important;
      border-radius: 6px;
      padding: 5px 5px 5px 5px;
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 0%;
      margin-left: 0px;
    height: auto;
    /* position:absolute;
        top:-10px; 
        right:9999px;
        width:200px;
        margin-right:-220px;
        padding:10px;
        color:#fff;
        background:#333;
        z-index: 100; */
  }
  
  
  .ToolTip:hover .ToolTipText {
    visibility: visible;
    }



@media screen and (min-width:1024px) and (max-width:1380px){
   .router-header1{
        padding-left: 2%;
        padding-right: 2%;
   }
   .table-st{
        padding-left: 2%;
        padding-right: 2%;
   }
    
}
@media screen and (min-width:1380px){
    
}

@media screen and (max-width:1380px){
   
}





/* table header fixed */
.fixed_headers thead{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
}



#leftmenubar{
    /* margin-top: 60px;
    left: 0;
    width: 225px;;
    height: 100%;
    position: fixed;
    z-index: 10;
    background-size: cover;
    background-position: -100px 0;
    background-repeat: no-repeat;
   background-image: url('./forestback.jpg'); 
    background: #5d2cb4;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s; */

     height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 225px;
    overflow-y: auto;
    -webkit-transition: .2s;
    transition: .2s;
    z-index: 5;
    background-image: url(/static/media/sidebar-2.10ba4eb8.png); 
    background-repeat: repeat;
    background-size: auto;
    background-position: bottom;
    background-color: #5d2cb4;
    /* background-size: cover;
    background-position: -100px 0;
    background-repeat: no-repeat; */
    margin-top: 60px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); 
}

#leftsubcontainer{
    /* position: relative;
    background: rgba(7, 87, 218, 0.75);
    color: #FFFFFF;
    font: 18px Arial, sans-serif;
    width:225px;
    height: 100%; */

    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 225px;
    overflow-y: auto;
    -webkit-transition: .2s;
    transition: .2s;
    z-index: 5;
    background-image: url(/static/media/sidebar-2.10ba4eb8.png); 
    background-repeat: repeat;
    background-size: auto;
    background-position: bottom;
    background-color: #5d2cb4;
    /* background-size: cover;
    background-position: -100px 0;
    background-repeat: no-repeat; */
    /* margin-top: 60px; */
   
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);     
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


#leftmenulist{
    list-style: none;
}

 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 200px;
    color: white;
    width: 100%;
  }
  ul li{
     
     
  }
  
  li a {
    display: -webkit-flex;
    display: flex;
    /* padding: 5px 25px 10px; */
    padding: 3px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    /* font-weight: 00; */
  }
  
  li a.active {
    background-color: #6831c8;
    color: white;
  }
  
  li a:hover:not(.active) {
    background-color: #6831c8;
  }

  .menuicons {
      padding-top: 10px;
      padding-bottom: 5px;
  }
  .menuicons span{
    padding-top: 15px;
  }
  
  .pad-top-5{
    padding-top: 5px
  }

  .menuicons img {
      margin-right: 25px;
      color: rgba(255, 255, 255, 0.7);
      padding-left: 15px;
  }
  
  .backblue{
    background-color: #6831c8;
      color: white;
  }

/* 
  @font-face {
    font-family: 'revicons';
    src: url('../../font/lisboasansosf-italic.otf') format("otf"),;

    font-weight: normal;
    font-style: normal;
  } */

  /* @font-face {
    font-family: "Bitstream Vera Serif Bold";
    src: url("https://mdn.mozillademos.org/files/2468/VeraSeBd.ttf");
  } */
/* .menuicons{
  font-family: Lucida Console, Courier, monospace;
}
  */
/* 
@media (min-width: 1200px){
    .dashboard-container {
        max-width: 1140px;
    }
}


@media (min-width: 992px){
    .dashboard-container {
        max-width: 960px;
    }
}

@media (min-width: 768px){
    .dashboard-container {
        max-width: 720px;
    }
}

@media (min-width: 576px){
    .dashboard-container {
        max-width: 540px;
    }
}

.dashboard-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} */

.dashboard-dropdown-menu.showYear {
    display: none;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.dashboard-dropdown-menu.showMonth {
    display: none;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.dashboard-dropdown-menu.showUsers {
    display: none;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-moz-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-webkit-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
-ms-box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.15);
}

.dashboard-dropdown-menu[x-placement^="top"], .dashboard-dropdown-menu[x-placement^="right"], .dashboard-dropdown-menu[x-placement^="bottom"], .dashboard-dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dashboard-dropdown-menu {
    padding: 5px 0;
    border-radius: 4px;
    border: 0;
    margin-top: -1px;
    /* position: absolute;
    top: 100%;
    left: 0; */
    z-index: 1000;
    /* display: none; */
    float: left;
    min-width: 10rem;
    /* padding: 0.5rem 0; */
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; */
}

.dashboard-dropdown-menu .dashboard-dropdown-item:first-child {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}
.dashboard-dropdown-menu .dashboard-dropdown-item {
    padding: .75em 1em;
}
.dashboard-dropdown-item {
    display: block;
    /* width: 100%; */
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dashboard-dropdown-menu .dashboard-dropdown-item:first-child {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

.dashboard-dropdown-item:hover{
    background-color:  #e9e9e9;
    cursor: pointer;
}


/* //-----------------------------------------------------------------------------------------------// */

  #dashboard-card{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    background: rgba(255, 255, 255, 0.5);
    padding: 15px;
    height: -webkit-fill-available;
  }

  #dashboard-card-icon{
    height: 50px;
    width: 50px;
    line-height: 47px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    margin-right: 1rem !important;
  }
  .dashboard-card-icon-success{
    background-color: rgba(15, 215, 147, 0.15) !important;
    color: #0fd793;
  }

  .dashboard-card-icon-danger{
    background-color: rgba(246, 59, 85, 0.15) !important;
    color: #f63b55;
  }

  .dashboard-card-icon-warning{
    background-color: rgba(245, 168, 17, 0.15) !important;
    color: #f5a811;
  }

  .dashboard-card-icon-primary{
    background-color: rgba(121, 121, 255, 0.15) !important;
    color: #7979ff;
  }

  #dashboard-card-text p{
    line-height: 1.4em;
    margin-bottom: 0;
    color: #888888;
    display: inline-block;
    width: 100%;
    margin-top: auto;
  }

  #dashboard-card-text h4{
    font-size: 1.4rem;
    font-weight: 500 !important;
    color: #000000;
    line-height: 1.2;
    margin-top: 0;
    text-align: left;
  }

.dashshadow{
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}
   
.parent-layer{
  width: 100%;
  height: 95px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.middle-layer{
  height: 100%;
  width: 48.6%;
  display: -webkit-flex;
  display: flex;
}

.bottom-layer{
 width: 47%;
 height: 100%;
}

.layer-r-mr{
  margin-right: 30px;
}
.white-back{
  background-color: #FFFFFF;
}

.dashboard-graph{
  width: 100% !important;
}

.dashboard-select select {
  color: #FFFFFF; 
 }

 .dashboard-select option {
  color: black;  
}




@media only screen  and (max-width: 1000px) {
  .middle-layer{
    width: 100%;
    margin-top: 20px;
  }
  .parent-layer{
    display: block;
  }

}


@media only screen and (max-width: 768px) {
  .bottom-layer{
    width: 100%;
    margin-top: 20px;
  }

  .middle-layer{
  display: block;
    margin-bottom: 140px;
  }
  .body{
    background-color: #000000;
  }
} 


.dashboard-col{
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
}

.set-cus-family{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* .dashboard-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
} */

/* .dashboard-content-color-primary {
    color: #000000;
} */

/* .dashboard-page-title {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    font-weight: normal;
} */

/* .dashboard-content-color-secondary {
    color: #888888;
} */

/* .dashboard-page-sub-title{
    font-size: 14px;
    line-height: 24px;
    margin: 0;
} */
/* .dashboard-col-auto{
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
} */

.dashboard-btn{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dashboard-btn-danger:focus{
    box-shadow: 0 0 0 0.2rem rgba(246, 59, 85, 0.5);
    outline: none;
    color: #fff;
    background-color: #f63b55;
    border-color: #f63b55;
}

.dashboard-btn-danger:hover{
    background-color: #ff1837;
}

.dashboard-btn-success {
    color: #fff;
    background-color: #0fd793;
    border-color: #0fd793;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dashboard-btn-success:hover{
    background-color: #2e9772;
}

.dashboard-btn-success:focus{
    box-shadow: 0 0 0 0.2rem #0fd793;
    outline: none;
    color: #fff;
    background-color: #0ca772;
    border-color: #0b9b6a;
}

.dashboard-btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dashboard-btn-dark:hover{
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.dashboard-btn-dark:focus{
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    outline: none;
}


.dashboard-btn-group, .dashboard-btn-group-vertical {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    vertical-align: middle;
}

/* .dashboard-btn-danger:not(:disabled):not(.disabled):active:focus, .dashboard-btn-danger:not(:disabled):not(.disabled).active:focus, .show > .dashboard-btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 59, 85, 0.5);
}

.dashboard-btn-danger:not(:disabled):not(.disabled):active, .dashboard-btn-danger:not(:disabled):not(.disabled).active, .show > .dashboard-btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f30b2b;
    border-color: #e70b29;
} */

.dashboard-toggle-arrow{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}





/* .create-btn{
    font-size: 0.875em;
    margin-top: 5px;
    padding: 5px 15px 5px 15px;
    line-height: 1.5;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif
}
.create-btn-color{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
} */

.back-btn{
    font-size: 16px;
    padding: 10px 15px 5px 15px;
    line-height: 1.5;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    /* font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif */
}
.back-btn-color{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
}

.back-btn span{
    margin-bottom: 20px;
}

.addVendor-main-div{
    /* display: flex;
    justify-content: center !important; */
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 83.33333%;
}

.addVendor-main-div-2{
    width: 100%;
    text-align: center;
    margin-top: 2.5rem ;
}
.addVendor-main-div-2 h5{
    font-size: 1.1em;
    margin-top: 1.5rem ;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}
.addVendor-main-div hr{margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}



.addVendorCheckbox{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}
.toggle-form{
    height: 70px;
    overflow: hidden;
    width: 100%;
    padding-bottom: 50px;
    padding-right: 80px;
    padding-left: 80px;
    background-color: white;
    /* style={{height: "70px",overflow: "hidden", width:"100%", paddingBottom: "50px",paddingRight:"80px",paddingLeft:"80px", backgroundColor: "white"}} */
}


.show-full-div{
    height: 100%;
}

.quota-quali{
    background-color: grey;;
}

.url-mr-top{
    margin-top: 20px;
}

.tg-hide{
    display: none;
}

.quota-tbl-hide{
    display: none;
}

.pro-tbl-hide{
    display: none;
}

.url-text{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 2.0;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 82%;
}
.url-text:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
}

.url-btn{
    padding: 10px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    /* height: 30px; */
    position: absolute;
    right: 0px;
}


.url-btn-color{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
}

.url-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}

.url-input{
    width: "100%";
    display: -webkit-flex;
    display: flex;
    position: relative;
    margin-bottom: 20px;
    /* style={{width: "100%",display: "flex", position: "relative", marginBottom: "20px"}} */
}

.content-sp-bt{
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.pro-box{
    height: 80px;
    padding: 10px;
    width: 20%;
}

.box-color-1{
    background-image: -webkit-linear-gradient(45deg, #07ba7d 0%, #0fd793 100%);
}

.box-color-2{
    background-image: -webkit-linear-gradient(45deg, #cf0723 0%, #f63b55 100%);
}
.box-color-3{
    background-image: -webkit-linear-gradient(45deg, #ff7800 0%, #f5a811 100%);
}

.box-radius{
    border-radius: 3px;
}

.pro-btn{
   padding: 8px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    color: white;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    height:35px;
    width: 150px;
    border: none;
    color: white;
    margin-bottom: 15px;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}
.pro-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}
.mar-bot{
    margin-bottom: 10px;
}

.pro-card-header{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100px;
}
.card-border{
    border-radius: 5px;
}

.pro-layer{
    height: 100%;
    width: 49%;
    display: -webkit-flex;
    display: flex;
}

.box-1-color{
    background-image: -webkit-linear-gradient(45deg, #07ba7d 0%, #0fd793 100%);
    background-color: #07ba7d !important;
}


.box-2-color{
    background-image: -webkit-linear-gradient(45deg, #cf0723 0%, #f63b55 100%)
}

.box-3-color{
    background-image: -webkit-linear-gradient(45deg, #ff7800 0%, #f5a811 100%);
}

.pro-first-layer{
    display: -webkit-flex;
    display: flex;
    width: 48%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.pro-bottom-layer{
    display: -webkit-flex;
    display: flex;
    width: 46%;
    padding-top: 30px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-radius: 5px;
  }
.pro-card-content{
    width: 50%;
    padding-left: 10%;
}
.pro-card-content p{
    margin: 0px;
    color: white;
}

.pro-card-content h3{
    margin: 0px;
    text-align: left;
}

.pro-card-img{
    width: 100%;
    text-align: right;
    margin-right: 10px;
}
.pro-card-img img{
    height: 25px;
    width: 25px;
    
}


#pro-tbl th{
    /* background-color: #8226f5; */
    color: white;
    font-size: 15px;
}



/* #pro-tbl {
    width: 100%;
    border-collapse: collapse;
  }


  #pro-tbl th {
      text-align: left;
      color: white;
      font-size: 15px;
  }

  #pro-tbl th, td{
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
    padding: 0.75rem;
    border: none;
  } */



.pro-card-img-circle{
    height: 50px;
    width: 50px;
    line-height: 47px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.pro-tgl-btn{
    height: 40px;
    width: 40px;
    line-height: 36px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    color: blue;
    cursor: pointer;
    font-size: 20px;
}

.pro-tgl-btn-shdw{
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}

.rotate-tgl{ /* IE 9 */
    -webkit-transform: rotate(-180deg); /* Chrome, Safari, Opera */
    transform: rotate(-180deg);
}


/* .create-btn{
    font-size: 0.875em;
    margin-top: 5px;
    padding: 5px 15px 5px 15px;
    line-height: 1.5;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif
}
.create-btn-color{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
} */

.back-btn{
    font-size: 16px;
    padding: 10px 15px 5px 15px;
    line-height: 1.5;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    /* font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif */
}
.back-btn-color{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
}

.back-btn span{
    margin-bottom: 20px;
}

.addVendor-main-div{
    /* display: flex;
    justify-content: center !important; */
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 83.33333%;
}

.addVendor-main-div-2{
    width: 100%;
    text-align: center;
    margin-top: 2.5rem ;
}
.addVendor-main-div-2 h5{
    font-size: 1.1em;
    margin-top: 1.5rem ;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}
.addVendor-main-div hr{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}



.addVendorCheckbox{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

#rolename{
    text-transform: lowercase;
}
#rolename::first-letter{
    text-transform: capitalize;
}

.map-card-top{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 40px;
    width: 100%;
    height: 100px;
}

.map-card-midd{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 49%;
    height: 100px;
}
.map-card-bottom{
    width: 48%;
    background-color: brown;
    border-radius: 4px;
}
.map-box{
    text-align: center;
    color: white;
}

.map-box p{
    margin: 0;
    margin-top: 20px;
}

.map-box h3{
    margin: 0;
   margin-top: 5px; 
}


.map-box-1{
    background-image: -webkit-linear-gradient(45deg, #07ba7d 0%, #0fd793 100%);
    background-color: #07ba7d;
}

.map-box-2{
    background-image: -webkit-linear-gradient(45deg, #cf0723 0%, #f63b55 100%);
    background-color: #cf0723;
}

.map-box-3{
    background-image: -webkit-linear-gradient(45deg, #ff7800 0%, #f5a811 100%);
    background-color: #ff7800;
}

.map-box-4{
    background-image: -webkit-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%);
    background-color: #4a19c8;
}


.box-hide-1{
    display: none;
}
.box-hide-2{
    display: none;
}

.box-hide-3{
    display: none;
}

.box-hide-4{
    display: none;
}

.box-hide-5{
  display: none;
}

.url-text-1{
    width: 100%;
}

.url-text-1 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}
.url-text-1 input{
    width: 90%;
}


.eye-st{
    display: -webkit-flex;
    display: flex;
    height: 30px;
    width: 50px;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    background-color: #8226f5 !important;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.eye-st:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}

.eye {
   
    width: 15px;
    height: 15px;
    border: solid 1px #000;
    border-radius:  75% 15%;
    position: relative;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background-color: white;
  }
  .eye:before {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border: solid 1px #000;
    border-radius: 50%;
    left: 4px;
    top: 4px;
  }

  .updateMappingSearchByStatus{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 5px;
    color: white;
    font-size: 16px;
    width: 150px;
    height : 35px;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  }

  .updateMappingSearchByStatus:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
  }

  .updateMappingSearchByStatus option {
    margin: 40px;
    background: white;
    color: black;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    padding: 5px;
    font-size: 15px;
}

.updateMappingSearchByStatus option:hover{
    color: rgb(6, 4, 134);
}


.mappingtable {
    width: 100%;
    border-collapse: collapse;
  }

.mappingtable th {
      text-align: left;
  }

.mappingtable, th, td{
  
    padding: 0.75rem;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
  }
  .tbllayout, th, td{
      border: 1px solid rgb(236, 236, 236,0.9) !important;
  }
  .gradienthead{
      /* background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important */
      
  }
  .gradienthead tr th:nth-child(1){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(2){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(3){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(4){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(5){
    background-image: -webkit-linear-gradient( left, #8226f5, #E625FD );
    background-image: linear-gradient( to right, #8226f5, #E625FD );
  }
  .gradienthead tr th:nth-child(6){
    background-image: -webkit-linear-gradient( left, #E625FD , #E625FD );
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }

  .gradienthead tr th:nth-child(7){
    background-image: -webkit-linear-gradient( left, #E625FD , #E625FD );
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }
  .gradienthead tr th:nth-child(8){
    background-image: -webkit-linear-gradient( left, #E625FD , #E625FD );
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }

  #mapp-unique{
    border-left: none !important;
    border-right: none !important;

  }

  #dragheading ul{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding-bottom: 15px;
    color: black;
  }

  #dragdata ul{ 
    cursor: pointer;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    color: black;
    margin-bottom: 1px;
  }
 

  #dragdata ul:hover{ 
    color: white;
    background-color: #6831c8;
  }

  #counter{
    text-align: center;
    width: 30%;
    font-weight: 700;
  }

#counter1{
  width: 30%;
  text-align: center;
  padding : 10px 0px 10px 0px;
}

#screenername{
    font-weight: 700;
    width: 50%;
    text-align: left;
  }
 
#screenername1{
  /* padding-top: 20px; */
  text-align: left;
  width: 50%;
  padding : 10px 0px 10px 0px;

}
#eyeicon1{
   width: 20%;
}
#eyeicon{
    font-weight: 700;
    width: 20%;
    color: black; 
    padding : 0px 0px 10px 0px;
  }
.map-card-top{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 40px;
    width: 100%;
    height: 100px;
}

.map-card-midd{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 49%;
    height: 100px;
}
.map-card-bottom{
    width: 48%;
    background-color: brown;
    border-radius: 4px;
}
.map-box{
    text-align: center;
    color: white;
}

.map-box p{
    margin: 0;
    margin-top: 20px;
}

.map-box h3{
    margin: 0;
   margin-top: 5px; 
}


.map-box-1{
    background-image: -webkit-linear-gradient(45deg, #07ba7d 0%, #0fd793 100%);
    background-color: #07ba7d;
}

.map-box-2{
    background-image: -webkit-linear-gradient(45deg, #cf0723 0%, #f63b55 100%);
    background-color: #cf0723;
}

.map-box-3{
    background-image: -webkit-linear-gradient(45deg, #ff7800 0%, #f5a811 100%);
    background-color: #ff7800;
}

.map-box-4{
    background-image: -webkit-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%);
    background-color: #4a19c8;
}


.box-hide-1{
    display: none;
}
.box-hide-2{
    display: none;
}

.box-hide-3{
    display: none;
}

.box-hide-4{
    display: none;
}

.box-hide-5{
  display: none;
}

.url-text-1{
    width: 100%;
}

.url-text-1 label{
    display: inline-block;
    margin-bottom: 0.5rem;
}
.url-text-1 input{
    width: 90%;
}


.eye-st{
    display: -webkit-flex;
    display: flex;
    height: 30px;
    width: 50px;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    background-color: #8226f5 !important;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.eye-st:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}

.eye {
   
    width: 15px;
    height: 15px;
    border: solid 1px #000;
    border-radius:  75% 15%;
    position: relative;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background-color: white;
  }
  .eye:before {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border: solid 1px #000;
    border-radius: 50%;
    left: 4px;
    top: 4px;
  }

  .updateMappingSearchByStatus{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 5px;
    color: white;
    font-size: 16px;
    width: 150px;
    height : 35px;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  }

  .updateMappingSearchByStatus:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
  }

  .updateMappingSearchByStatus option {
    margin: 40px;
    background: white;
    color: black;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    padding: 5px;
    font-size: 15px;
}

.updateMappingSearchByStatus option:hover{
    color: rgb(6, 4, 134);
}


.mappingtable {
    width: 100%;
    border-collapse: collapse;
  }

.mappingtable th {
      text-align: left;
  }

.mappingtable, th, td{
  
    padding: 0.75rem;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid rgb(236, 236, 236,0.9) !important;
  }
  .tbllayout, th, td{
      border: 1px solid rgb(236, 236, 236,0.9) !important;
  }
  .gradienthead{
      /* background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important */
      
  }
  .gradienthead tr th:nth-child(1){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(2){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(3){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(4){
    background-image: -webkit-linear-gradient(45deg, #8226f5 100%, #e625ea 100%);
  }
  .gradienthead tr th:nth-child(5){
    background-image: -webkit-linear-gradient( left, #8226f5, #E625FD );
    background-image: linear-gradient( to right, #8226f5, #E625FD );
  }
  .gradienthead tr th:nth-child(6){
    background-image: -webkit-linear-gradient( left, #E625FD , #E625FD );
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }

  .gradienthead tr th:nth-child(7){
    background-image: -webkit-linear-gradient( left, #E625FD , #E625FD );
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }
  .gradienthead tr th:nth-child(8){
    background-image: -webkit-linear-gradient( left, #E625FD , #E625FD );
    background-image: linear-gradient( to right, #E625FD , #E625FD );
  }

  #mapp-unique{
    border-left: none !important;
    border-right: none !important;

  }
.create-btn{
    font-size: 0.875em;
    margin-top: 10px;
    padding: 7px 15px 5px 15px;
    line-height: 1.5;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    /* font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif */
}
.create-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
    /* background-color: #4a19c8; */
}

.back-btn{
    margin-top: 10px;
    font-size: 16px;
    padding: 10px 15px 5px 15px;
    line-height: 1.5;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    /* font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif */
}
.back-btn-color{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
}

.back-btn span{
    margin-bottom: 20px;
}
.back-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}

.addVendor-main-div{
    /* display: flex;
    justify-content: center !important; */
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 83.33333%;
}

.addVendor-main-div-2{
    width: 100%;
    text-align: center;
    margin-top: 2.5rem ;
}
.addVendor-main-div-2 h5{
    font-size: 1.1em;
    margin-top: 1.5rem ;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}
.addVendor-main-div hr{margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}



.addVendorCheckbox{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.showtemp{
    display: block;
}
/* .alert-box-container{
    display: flex;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 23;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    
}

.alert-box{
    width: 400px;
    height: 250px;
    background: white;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
}
#alert-dialog{
    display: none;
} */
#rec-pad{
    padding: 30px 80px 5px 80px;
    
}

#rep-h4{
    text-align: center;
    font-weight: 500 !important;
    color: black;
}
#tbl-full{
    width: 100%;
}

#reciept-back{
   
    background: url(/static/media/invoicebg.22191900.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 30px;
  padding-top: 30px;
}


#mr-top-30{
    margin-top: 30px;
}

#logo-title{
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    border: none;
   
}

#refno{
    margin-top: 20px;
    text-align: center;   
}
#txt-left{
    text-align: left;
}


#border-rm{
    border: none !important;
}
#border-rm-txt-left{
    border: none;
    text-align: left;
}

#table-data{
    width: 100%;
    border-collapse: collapse;
}


#border-rm-txt-left-padd-h{
    border: none;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}

#border-rm-txt-left-padd{
    border: none;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 50px;
}

#border-rm-txt-left-padd-d{
    border: none;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
}
#border-rm-txt-left-padd-d-w{
    border: none;
    text-align: left;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
}

#border-rm-txt-left-padd-d-b{
    border: none;
    text-align: left;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
}

#tbl-data-gray{
    background-color: rgb(228, 228, 228,0.4);
    border: none;
}

#tbl-data-white{
    background-color: rgb(255, 255, 255,0.4);
    border: none;
}


#rec-add-tbl{
    width: 100%;
}

#rec-add-tbl-h{
    padding:0;
    border: none !important;
    text-align: left;
    /* padding-left: 50px; */
}

#rec-add-tbl-d{
    text-align: left;
    padding: 0;
    border: none  !important;
    /* padding-right: 50px; */
}

#grand-total-tbl{
    width: 100%;
}

#grand-total-tbl-left{
    padding: 0;
    padding-left: 50px;
    border: none  !important;
    text-align: left;
}
#grand-total-tbl-right{
    padding: 0;
    padding-right: 50px;
    border: none  !important; 
    text-align: left;
}

#table-data-left{
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    border: none  !important;
    text-align: left;
}
#table-data-mid{
    padding: 0;
    padding-right: 10px;
    border: none  !important;
    text-align: left;
}

#table-data-right{
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 50px;
    border: none  !important;
    text-align: left;
}

#txt-center{
    text-align: center;
}
#txt-center-padd{
    text-align: center;
    padding-top: 30px;
}



















#logo-h1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

#logo{
    padding-left: 50px;
   text-align: left;
}

#title{
    padding-right: 50px;
    text-align: right;
    margin-top: -25px;
}

#rec-h1{
    text-align: right;
}

#bottom-left{
    padding: 0;
    padding-left: 50px;
    border: none !important;
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;
}
#bottom-right{
    padding: 0;
    padding-right: 50px;
    border: none !important;
    text-align: right;
    padding-top: 30px;
    padding-bottom: 30px;
}


#projectname{
    margin-top: 20px;
    padding-left: 50px;
}


#invoiceaddressdetails{
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
}


#frm-1{
    padding-left: 50px;
}

#frm-2{
    padding-left: 150px;
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto;
}

#frm-3{
    padding-left: 140px;
}

#frm-4{
    padding-left: 150px;
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto;
}

#col-width{
    width: 100px;
}


#tbl-st-rec{
    margin-top: 20px;
  
}


#tbl-h-gird{
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}


#tbl-d-gird{
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
   background-color: rgb(228, 228, 228,0.4);
   padding-top: 16px;
    padding-bottom: 16px;
}


#tbl-blank-white-gird{
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    background-color: rgb(255, 255, 255,0.4);
    padding-top: 25px;
    padding-bottom: 25px;
}

#tbl-blank-gray-gird{
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    background-color: rgb(228, 228, 228,0.4);
    padding-top: 25px;
    padding-bottom: 25px;
}

#tbl-h-1{
    width: 50px;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-h-2{
    width: 250px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-h-3{
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-h-4{
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-h-5{
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#tbl-d-1{
    width: 50px;
    padding-left: 50px;
}

#tbl-d-2{
    width: 250px;
}

#tbl-d-3{
    width: 80px;
}

#tbl-d-4{
    width: 80px;
}

#tbl-d-5{
    width: 100px;
}

#hr-2{
    margin: 0 auto;
    height: 3px;
    width: 90%;
    margin-top: -2px;
    background-color: gray;
}

#grand-total-summary{
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
}

#total-part-1{
    padding-left: 50px;
    width: 250px;   
}

#total-part-2{
    padding-left: 150px;
    padding-right: 50px;
    display: grid;
    grid-template-columns: auto auto;
}


#trnid{
    font-size: 17px;
}

#amountinnumber{
    font-size: 18px;
}
#totalamt{
    font-size: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
}
#hr-1{
    background-color: black;
    height: 2px;
    border: none;
    margin: 0;
    width: 170px;
}
#hr-1-w{
    background-color: black;
    height: 2px;
    border: none;
    margin: 0;
    width: 130px;
}

#total-text{
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
}
#total-amt-text{
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#paymentinfo{
    margin-top: 20px;
}



.blanktd{
    padding-left: 30% !important;
}

.padd{
    padding: 0px;
    border: none !important;
}
.align{
    text-align: left;
}
.blank{
    padding-right: 30% !important;
}

#amthr{
    font-size: 15px;
    height: 2px;
    background-color: black;
    border: none;
  }
  

  #dragheading ul{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding-bottom: 15px;
    color: black;
  }

  #dragdata ul{ 
    cursor: pointer;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    color: black;
    margin-bottom: 1px;
  }
 

  #dragdata ul:hover{ 
    color: white;
    background-color: #6831c8;
  }

  #counter{
    text-align: center;
    width: 30%;
    font-weight: 700;
  }

#counter1{
  width: 30%;
  text-align: center;
  padding : 10px 0px 10px 0px;
}

#screenername{
    font-weight: 700;
    width: 50%;
    text-align: left;
  }
 
#screenername1{
  /* padding-top: 20px; */
  text-align: left;
  width: 50%;
  padding : 10px 0px 10px 0px;

}
#eyeicon1{
   width: 20%;
}
#eyeicon{
    font-weight: 700;
    width: 20%;
    color: black; 
    padding : 0px 0px 10px 0px;
  }
