.create-btn{
    font-size: 0.875em;
    margin-top: 10px;
    padding: 7px 15px 5px 15px;
    line-height: 1.5;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    /* font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif */
}
.create-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
    /* background-color: #4a19c8; */
}

.back-btn{
    margin-top: 10px;
    font-size: 16px;
    padding: 10px 15px 5px 15px;
    line-height: 1.5;
    border-radius: 0.2rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    /* font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif */
}
.back-btn-color{
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%);
}

.back-btn span{
    margin-bottom: 20px;
}
.back-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important; 
}

.addVendor-main-div{
    /* display: flex;
    justify-content: center !important; */
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 83.33333%;
}

.addVendor-main-div-2{
    width: 100%;
    text-align: center;
    margin-top: 2.5rem ;
}
.addVendor-main-div-2 h5{
    font-size: 1.1em;
    margin-top: 1.5rem ;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}
.addVendor-main-div hr{margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}



.addVendorCheckbox{
    display: flex;
    justify-content: center;
    width: 100%;
}

.showtemp{
    display: block;
}